import axios from 'axios';
import {
    HOME_DETAILS_REQUEST,
    HOME_DETAILS_SUCCESS,
    HOME_DETAILS_FAIL
} from "../constants/homeConstants";
import { BASE_URL } from '../constants/Globals';

export const listhomeDetails = () => async (dispatch) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.success.token}`,
        },
    };

    try {
        dispatch({ type: HOME_DETAILS_REQUEST });

        let resdata;
        
        if (userInfo.user.shop_id != null){
            const { data } = await axios.get(
                `${BASE_URL}api/v2/admin/analytics?shop_id=${userInfo.user.shop_id}`,
                config
            );
            resdata = data;
        } else {
            const { data } = await axios.get(
                `${BASE_URL}api/v2/admin/analytics`,
                config
            );
            resdata = data;
        }
        const data = resdata;
        console.log("Data:", data)
        
        dispatch({
            type: HOME_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error){
        dispatch({
            type: HOME_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        });
    }
};
