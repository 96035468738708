import {
    APPSETTINGS_REQUEST,
    APPSETTINGS_SUCCESS,
    APPSETTINGS_FAIL,
    APPSETTINGS_UPDATE_REQUEST,
    APPSETTINGS_UPDATE_SUCCESS,
    APPSETTINGS_UPDATE_FAIL
  } from "../constants/appSettingsContants";
  
  export const appSettingsDetailsReducer = (state = {loading: true,  appSettingsDetails: undefined}, action) => {
    switch (action.type) {
      case APPSETTINGS_REQUEST:
      console.log("IN REDUCER:::   ",action);
  
        return { loading: true, appSettingsDetails: undefined };
      case APPSETTINGS_SUCCESS:
      console.log("IN REDUCER:::   ",action);
        return {
          loading: false,
          appSettingsDetails: action.payload,
        };
      case APPSETTINGS_FAIL:
      console.log("IN REDUCER:::   ",action);
  
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  
  
  