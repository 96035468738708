class Asset {
    id:number|null;
    isNetworkAsset: boolean;
    imagePath: string;
    file: File|null;

    constructor(
        id:number|null,
        isNetworkAsset: boolean,
        imagePath: string,
        file: File|null

    ) {
        this.id = id;

        this.isNetworkAsset = isNetworkAsset;
        this.imagePath = imagePath;
        this.file = file;


    }


}

export default Asset;