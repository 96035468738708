import {
    HOME_DETAILS_FAIL,
    HOME_DETAILS_REQUEST,
    HOME_DETAILS_SUCCESS,
} from "../constants/homeConstants";

export const homeDetailsReducer = ( state = {loading: true, home: [] }, action)=> {
    switch (action.type) {
        case HOME_DETAILS_REQUEST:
            return { loading: true, home:[] };
        
        case HOME_DETAILS_SUCCESS:
            return {
                loading: false,
                home: action.payload,
            };
        
        case HOME_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};