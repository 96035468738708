class Shop {
    id: string;
    coverImage: string;
    mob: string;
    email: string;
    website: string;
    active: boolean;
    bannerRatio: number;
    name: string;
    bannerImage: string;


    constructor(
        id: string,
        coverImage: string,
        mob: string,
        email: string,
        website: string,
        active: boolean,
        bannerRatio: number,
        name: string,
        bannerImage: string) {

        this.id = id;
        this.coverImage = coverImage;
        this.mob = mob;
        this.email = email;
        this.website = website;
        this.active = active;
        this.bannerRatio = bannerRatio;
        this.name = name;
        this.bannerImage = bannerImage;

    };


    static fromJson(json: any): Shop {

        return new Shop(
            json.id.toString(),
            json.fullurl,
            json.shop_mob,
            json.shop_email,
            json.shop_website,
            json.status,
            Number(json.banner_ratio),
            json.shop_name,
            json.bannerimage
        );
    }
}

export default Shop