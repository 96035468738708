import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
// import "./Style2.css";
import {
  Button,
  Card,
  Col,
  Modal,
  Table,
  Row,
  Container,
} from "react-bootstrap";
import TextField from "../components/TextField";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../actions/categoryActions";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { getAllShops } from "../../actions/shopActions";
import Loader from "../components/Loader";
import ChooseVariationTypeModal from "./ChooseVariationTypeModal";
import AddVariationModal from "./AddVariationModal";

import ProductsApi from "../../apis/productsapi";
import ShopsApi from "../../apis/shopsapi";
import CategoriesApi from "../../apis/categoriesapi";
import Asset from "../../models/assetmodel";
import Message from "../components/Message";
import Variation from "../../models/variationmodel";

import axios from "axios";
// import { BASE_URL } from "../../constants/Globals";

const AddProductScreenV2 = ({ history, match }) => {
  console.log("history", history);
  const productId = match.params.id;
  const isEdit = productId != null;
  const dispatch = useDispatch();

  const [allImageList, setAllImageList] = useState([]);
  const [deletedImageIndexesList, setDeletedImageIndexesList] = useState([]);
  const [hasOffer, setHasOffer] = useState(false);
  const [hasVariant, setHasVariant] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [hasColorVariant, setHasColorVariant] = useState(true);
  const [hasSizeVariant, setHasSizeVariant] = useState(false);
  const [showAddVariationModal, setShowAddVariationModal] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [variationList, setVariationList] = useState([]);
  const [selectedVariationInfo, setSelectedVariationInfo] = useState(null);
  const [productResponse, setProductResponse] = useState({ loading: true });
  const [shopListResponse, setShopListResponse] = useState({ loading: true });
  const [categoryListResponse, setCategoryListResponse] = useState({
    loading: true,
  });
  const [isCreatingOrUpdatingResponse, setIsCreatingOrUpdatingResponse] =
    useState({ isLoading: false, success: true });

  const [deletedVariationsIdList, setDeletedVariationsIDList] = useState([]);

  const formikRef = useRef();

  console.log("imageList", allImageList);

  console.log("variaritionList", variationList);

  // const categoryList = useSelector((state) => state.categoryList);
  // const { loading: loadingCategory, categoryError, category } = categoryList;
  // const allshops = useSelector((state) => state.allshops);
  // const { loading: shoploading, shopError, shops } = allshops;

  useEffect(() => {
    if (formikRef.current != null) {
      const seletedImageList = allImageList.filter(
        (asset) => !deletedImageIndexesList.includes(asset.id)
      );
      formikRef.current.setFieldValue("images", seletedImageList);
    }
  }, [allImageList, deletedImageIndexesList]);

  useEffect(() => {
    if (formikRef.current != null) {
      formikRef.current.setFieldValue("variations", variationList);
    }
  }, [variationList, hasVariant]);

  useLayoutEffect(() => {
    // dispatch(getCategory());
    // dispatch(getAllShops());

    ShopsApi.getAllShops()
      .then((shops) => {
        setShopListResponse({
          loading: false,
          success: true,
          value: shops,
          error: null,
        });

        console.log("shopList", shops);
      })
      .catch((e) => {
        setShopListResponse({
          loading: false,
          success: false,
          value: null,
          error: e,
        });
        console.log("FAILED TO GET ALL SHOPS ", e);
      });

    CategoriesApi.getAllCategories()
      .then((categories) => {
        console.log("categoriesList", categories);

        setCategoryListResponse({
          loading: false,
          success: true,
          value: categories,
          error: null,
        });
      })
      .catch((e) => {
        setCategoryListResponse({
          loading: false,
          success: false,
          value: null,
          error: e,
        });
        console.log("FAILED TO GET ALL SHOPS ", e);
      });

    if (isEdit) {
      ProductsApi.getProductDetail(productId)
        .then((product) => {
          const hasVariant =
            product.hasColorVariation || product.hasSizeVariation;
          setHasColorVariant(product.hasColorVariation);
          setHasSizeVariant(product.hasSizeVariation);
          setIsActive(product.isActive);
          setIsSpecial(product.isSpecial);
          setIsBestSeller(product.isBestSeller);
          setHasOffer(
            product.hasSizeVariation ? false : product.variations[0].hasOffer
          );

          setHasVariant(product.hasColorVariation || product.hasSizeVariation);
          setVariationList(product.variations);

          if (!hasVariant) {
            setAllImageList(product.variations[0].images);
          }
          console.log("GOT THE PRODUCT", product);
          setProductResponse({
            loading: false,
            success: true,
            value: product,
            error: null,
          });
        })
        .catch((e) => {
          console.log("FAILED TO GET THE PRODUCT", e);
          setProductResponse({
            loading: false,
            success: false,
            value: null,
            error: e,
          });
        });
    }
  }, []);

  const populateCategory = () => {
    let objects = [];

    if (!categoryListResponse.loading && categoryListResponse.success) {
      let categoryList = categoryListResponse.value;
      objects = [categoryList.length];
      for (var x = 0; x < categoryList.length; x++) {
        objects[x] = {
          label: categoryList[x].name_en,
          value: categoryList[x].id,
        };
      }
      // objects.unshift({ label: "Select Category", value: "" });
      console.log("in populate category", objects);
    }

    return objects;
  };

  const populateShops = () => {
    let objects = [];

    const user = JSON.parse(localStorage.getItem("userInfo"));

    if (!shopListResponse.loading && shopListResponse.success) {
      let shopList = shopListResponse.value;
      objects = [shopList.length];
      for (var x = 0; x < shopList.length; x++) {
        objects[x] = { label: shopList[x].name, value: Number(shopList[x].id) };
      }
      // objects.unshift({ label: "Select Shop", value: "" });
    }

    return objects;
  };

  const validationSchema = () => {
    return Yup.object({
      name_ar: Yup.string().required("Required"),
      name_en: Yup.string().required("Required"),
      category_id: Yup.number().required("Required"),
      shop_id: Yup.number().required("Required"),
      description_ar: Yup.string().required("Required"),
      description_en: Yup.string().required("Required"),
      price: !hasVariant
        ? Yup.number().required("Required").min(0)
        : Yup.number().notRequired(),
      stocks: !hasVariant
        ? Yup.number().required("Required").min(0)
        : Yup.number().notRequired(),
      images: !hasVariant
        ? Yup.array().min(1, "At least one image is required")
        : Yup.array().notRequired(),
      offerprice:
        !hasVariant && hasOffer
          ? Yup.number()
              .required("Required")
              .min(0)
              .when(["price"], (price, schema) => {
                return schema.max(
                  price,
                  "offerprice cannot exceed regular price"
                );
              })
          : Yup.number().notRequired(),

      variations: hasVariant
        ? Yup.array().min(1, "At least one variant is required")
        : Yup.array().notRequired(),
    });
  };

  const onPressedUploadImage = (e, formik) => {
    console.log("e.target.files", e.target.files);
    if (e.target.files) {
      var isImageSizeValid = true;
      Array.from(e.target.files).forEach((file) => {
        if (file.size / (1024 * 1024) > 2) {
          alert("Please upload a maximum of 2MB ");
          isImageSizeValid = false;
        }
      });

      if (!isImageSizeValid) {
        return;
      }

      const assetArray = Array.from(e.target.files).map((file) => {
        const imageUrl = URL.createObjectURL(file);
        return new Asset(null, false, imageUrl, file);
      });

      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

      let tempArray = [...allImageList];
      tempArray.push(...assetArray);
      setAllImageList(tempArray);
    }

    e.target.value = null;
  };

  const onDeleteImage = (asset) => {
    let isNetworkAsset = !asset.imagePath.toString().includes("blob");

    if (isNetworkAsset) {
      let temparr = [...deletedImageIndexesList];
      temparr.push(asset.id);
      setDeletedImageIndexesList(temparr);
      // productResponse.value.variations[0].deletedImageIndexes = temparr;
    } else {
      let arr = allImageList.filter((e) => {
        return e.imagePath !== asset.imagePath;
      });
      setAllImageList(arr);
    }
  };

  // const renderChooseVariationModal = () => {
  //   return (
  //     <ChooseVariationTypeModal
  //       hasColorVariant={hasColorVariant}
  //       hasSizeVariant={hasSizeVariant}
  //       onPressedOk={blabla}
  //       onPressedCancel={() => {
  //         setShowChooseVariationTypeModal(false);
  //       }}
  //     />
  //   );
  // };

  const renderAddVariationModal = () => {
    return selectedVariationInfo == null ? (
      <AddVariationModal
        disableVariationChoice={variationList.length > 0}
        hasColorVariant={hasColorVariant}
        hasSizeVariant={hasSizeVariant}
        variation={null}
        onPressedSave={(variation) => {
          console.log("onPressedSacveCallback", variation);
          if (variationList.length <= 0) {
            if (variation.colorvalue != null) {
              setHasColorVariant(true);
            } else {
              setHasColorVariant(false);
            }

            if (variation.sizevalue != null) {
              setHasSizeVariant(true);
            } else {
              setHasSizeVariant(false);
            }
          }

          setVariationList([...variationList, variation]);
          setShowAddVariationModal(false);
        }}
        onPressedCancel={() => {
          setShowAddVariationModal(false);
        }}
      />
    ) : (
      <AddVariationModal
        disableVariationChoice={true}
        variation={selectedVariationInfo.variation}
        onPressedSave={(variation) => {
          let tempArray = [...variationList];
          tempArray.splice(selectedVariationInfo.index, 1, variation);

          setSelectedVariationInfo(null);
          setVariationList(tempArray);
          setShowAddVariationModal(false);
        }}
        onPressedCancel={() => {
          setSelectedVariationInfo(null);
          setShowAddVariationModal(false);
        }}
      />
    );
  };

  function onPressedAddNewVariation(e) {
    setShowAddVariationModal(true);
  }

  const renderUploadImageButton = (formik) => {
    return (
      <div>
        <div className="row g-3">
          <div className="col-12">
            <label
              style={{ cursor: "pointer" }}
              className="text-nowrap border shadow py-3 px-4 bg-white text-success add-photo rounded w-100"
              htmlFor="file"
            >
              <i className="bx bx-cloud-upload mx-2"></i>Upload New Image
            </label>
          </div>
          <div className="col">
            <input
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              multiple={true}
              onChange={(e) => onPressedUploadImage(e, formik)}
            />
          </div>
        </div>
        <ErrorMessage
          component="div"
          className="error text-danger"
          name={"images"}
        />
        {/* <ErrorMessage
              component="div"
              className="error text-danger"
              name={"image"}
            /> */}
      </div>
    );
  };

  const renderProductImageSection = (formik) => {
    const filteredImageList = allImageList.filter(
      (asset) => !deletedImageIndexesList.includes(asset.id)
    );

    return (
      <Row>
        {filteredImageList.map((asset, index) => {
          return (
            <div className="result rounded">
              <Card
                className="my-2 p-1 rounded"
                style={{ height: "180px", objectFit: "contain" }}
              >
                <Card.Img
                  style={{ height: "170px", objectFit: "contain" }}
                  src={asset.imagePath}
                  variant="top"
                  key={asset.imagePath}
                />
                <button
                  onClick={(e) => onDeleteImage(asset)}
                  type="button px-1"
                  className="btn btn-white text-danger rounded fs-3"
                  style={{ position: "absolute" }}
                >
                  <i className="bx bx-trash"></i>
                </button>
              </Card>
            </div>
          );
        })}
      </Row>
    );
  };

  const renderArabicNameField = () => {
    return <TextField label="Arabic Name" name="name_ar" type="text" />;
  };

  const renderEngLishNameField = () => {
    return <TextField label="English Name" name="name_en" type="text" />;
  };

  const renderArabicDescriptionField = () => {
    return (
      <TextField label="Arabic Description" name="description_ar" type="text" />
    );
  };
  const renderEnglishDescriptionField = () => {
    return (
      <TextField
        label="English Description"
        name="description_en"
        type="text"
      />
    );
  };

  const renderEnglishInfoField = () => {
    return (
      <TextField
        label="English Info(Optional)"
        name="englishInfo"
        type="text"
      />
    );
  };

  const renderArabicInfoField = () => {
    return (
      <TextField label="Arabic Info(اختياري)" name="arabicInfo" type="text" />
    );
  };

  const renderPriceField = () => {
    return <TextField label="Price" name="price" type="number" />;
  };

  const renderStockField = () => {
    return <TextField label="Stock" name="stocks" type="number" min="0" />;
  };

  const renderCategoryDropDown = () => {
    const getDefaultValue = () => {
      let defaultValue = { label: "Choose a category" };

      if (isEdit) {
        let initialSelectedCategory = categoryListResponse.value?.find(
          (e) => e.id == productResponse.value?.categoryId
        );

        if (initialSelectedCategory != null) {
          defaultValue = {
            value: productResponse.value?.categoryId,
            label: initialSelectedCategory.name_en,
          };
        }
      }

      return defaultValue;
    };

    console.log("defaultValue", getDefaultValue());

    return (
      <div>
        <label>Category</label>
        <Select
          label="Category"
          name="category_id"
          placeholder="Search Category"
          onChange={(option) =>
            formikRef.current?.setFieldValue("category_id", option.value)
          }
          defaultValue={getDefaultValue()}
          options={populateCategory()}
        ></Select>
        <ErrorMessage
          component="div"
          className="error text-danger"
          name={"category_id"}
        />
      </div>
    );
  };

  const renderShopDropDown = () => {
    const getDefaultValue = () => {
      let defaultValue = { label: "Choose a shop" };

      if (isEdit) {
        let initialSelectedShop = shopListResponse.value?.find(
          (e) => e.id == productResponse.value?.shopId
        );

        if (initialSelectedShop != null) {
          defaultValue = {
            value: shopListResponse.value?.shopId,
            label: initialSelectedShop.name,
          };
        }
      }

      return defaultValue;
    };
    return (
      <div>
        <label>Link To Shop</label>
        <Select
          name="shop_id"
          placeholder="Search Shop"
          options={populateShops()}
          defaultValue={getDefaultValue()}
          //   defaultValue={(shop && shop != null) ? { value: product[0]?.shop?.id, label: product[0]?.shop?.shop_name } : null}
          onChange={(e) => {
            formikRef.current?.setFieldValue("shop_id", e.value);
          }}
        />
        <ErrorMessage
          component="div"
          className="error text-danger"
          name={"shop_id"}
        />
      </div>
    );
  };

  const renderOfferPriceField = () => {
    return <TextField label="Offer Price" name="offerprice" type="number" />;
  };

  const renderHasVariantCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={hasVariant}
          onChange={(e) => {
            setHasVariant(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Has Variant
        </label>
      </div>
    );
  };

  const renderHasOfferCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={hasOffer}
          onChange={(e) => {
            setHasOffer(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Has Offer
        </label>
      </div>
    );
  };

  const renderIsActiveCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isActive}
          onChange={(e) => {
            setIsActive(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Active Status
        </label>
      </div>
    );
  };

  const renderIsSpecialCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isSpecial}
          onChange={(e) => {
            setIsSpecial(e.target.checked);
          }}
        />
        <label className="form-check-label" htmlFor="Active">
          Hot Item
        </label>
      </div>
    );
  };

  const renderIsBestSellerCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isBestSeller}
          onChange={(e) => {
            setIsBestSeller(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Best Seller
        </label>
      </div>
    );
  };

  const renderDeleteProductButton = () => {
    return (
      <button
        className="text-nowrap btn btn-outline-danger mx-2 rounded p-3 my-2"
        type="button"
        onClick={(e) => {
          setIsCreatingOrUpdatingResponse({ loading: true });
          ProductsApi.deleteProduct(Number(productId))
            .then((_) => history.goBack())
            .catch((e) => {
              setIsCreatingOrUpdatingResponse({
                loading: false,
                success: false,
                error: e,
              });
            });
        }}
      >
        Delete Product
      </button>
    );
  };

  const renderCreateButton = () => {
    return (
      <button
        className="text-nowrap btn btn-outline-success mx-2 rounded p-3 my-2"
        type="submit"
        onClick={async () => {
          if (formikRef.current != null) {
            let errors = await formikRef.current.validateForm(
              formikRef.current.values
            );
            let isFormValid = Object.keys(errors).length === 0;

            if (isFormValid) {
              let values = formikRef.current.values;

              console.log("values", values);
              console.log("allImageList", allImageList);
              console.log("deletedImageIndexesList", deletedImageIndexesList);
              console.log(
                "localAssetList",
                allImageList.filter((e) => !e.isNetworkAsset)
              );
              console.log("variationList", variationList);

              let formdata = new FormData();

              formdata.append("name_ar", values.name_ar);
              formdata.append("name_en", values.name_en);
              formdata.append("description_en", values.description_en);
              formdata.append("description_ar", values.description_ar);
              formdata.append("description_ar", values.description_ar);

              const catergoryId = Number(values.category_id);
              formdata.append(
                "category_id",
                Number.isNaN(catergoryId) ? null : values.category_id
              );

              const shopId = Number(values.shop_id);
              formdata.append(
                "shop_id",
                Number.isNaN(shopId) ? null : values.shop_id
              );

              formdata.append("sort_index", 1);
              formdata.append("bestseller", isBestSeller ? 1 : 0);
              formdata.append("special", isSpecial ? 1 : 0);
              formdata.append("isactive", isActive ? 1 : 0);

              if (!hasVariant) {
                formdata.append("variations[0][bio_en]", values.englishInfo);
                formdata.append("variations[0][bio_ar]", values.arabicInfo);
                formdata.append("variations[0][price]", values.price);

                formdata.append("variations[0][hasoffer]", hasOffer ? 1 : 0);
                formdata.append("variations[0][offerprice]", values.offerprice);
                formdata.append("variations[0][stocks]", values.stocks);
                formdata.append("variations[0][size_value]", null);
                formdata.append("variations[0][color_name]", null);
                formdata.append("variations[0][color_value]", null);

                // const selectedImageList =  allImageList.filter(asset=> !deletedImageIndexesList.includes(asset.id));
                const localFileList = allImageList
                  .filter((asset) => !asset.isNetworkAsset)
                  .map((asset) => asset.file);

                console.log("localAssetList", localFileList);

                localFileList.forEach((file) => {
                  formdata.append("variations[0][images][]", file);
                });

                // s
              } else {
                variationList.forEach((variation, index) => {
                  formdata.append(
                    `variations[${index}][bio_en]`,
                    values.englishInfo
                  );
                  formdata.append(
                    `variations[${index}][bio_ar]`,
                    values.ArabicInfo
                  );
                  formdata.append(
                    `variations[${index}][price]`,
                    variation.price
                  );
                  formdata.append(
                    `variations[${index}][hasoffer]`,
                    variation.hasOffer ? 1 : 0
                  );
                  formdata.append(
                    `variations[${index}][offerprice]`,
                    variation.offerprice
                  );
                  formdata.append(
                    `variations[${index}][stocks]`,
                    variation.stocks
                  );
                  formdata.append(
                    `variations[${index}][size_value]`,
                    variation.sizevalue
                  );
                  formdata.append(`variations[${index}][color_name]`, null);
                  formdata.append(
                    `variations[${index}][color_value]`,
                    variation.colorvalue
                  );
                  formdata.append(
                    `variations[${index}][color_name]`,
                    variation.colorName
                  );

                  // const selectedImageList =  allImageList.filter(asset=> !deletedImageIndexesList.includes(asset.id));

                  const localFileList = variation.images
                    .filter((asset) => !asset.isNetworkAsset)
                    .map((asset) => asset.file);

                  localFileList.forEach((file) => {
                    formdata.append(`variations[${index}][images][]`, file);
                  });
                });
              }
              setIsCreatingOrUpdatingResponse({ loading: true });
              ProductsApi.updateProduct(formdata)
                .then((_) => history.goBack())
                .catch((e) => {
                  setIsCreatingOrUpdatingResponse({
                    loading: false,
                    success: false,
                    error: e,
                  });
                });
            }
          }
        }}
      >
        Create Product
      </button>
    );
  };

  const renderAddNewVariationButton = () => {
    return (
      <button
        className="text-nowrap btn btn-outline-success mx-2 rounded p-3 my-2"
        type="button"
        onClick={onPressedAddNewVariation}
      >
        Add New Variation
      </button>
    );
  };

  const renderUpdateButton = () => {
    return (
      <button
        className="text-nowrap btn btn-outline-success mx-2 rounded p-3 my-2"
        type="submit"
        onClick={async () => {
          if (formikRef.current != null) {
            let errors = await formikRef.current.validateForm(
              formikRef.current.values
            );
            let isFormValid = Object.keys(errors).length === 0;

            if (isFormValid) {
              let values = formikRef.current.values;

              // console.log("values", values);
              // console.log("allImageList", allImageList);
              // console.log("deletedImageIndexesList", deletedImageIndexesList);
              // console.log("localAssetList", allImageList.filter(e => !e.isNetworkAsset))
              // console.log("variationList", variationList);

              let formdata = new FormData();
              formdata.append("id", Number(productId));

              formdata.append("name_ar", values.name_ar);
              formdata.append("name_en", values.name_en);
              formdata.append("description_en", values.description_en);
              formdata.append("description_ar", values.description_ar);
              formdata.append("description_ar", values.description_ar);

              const catergoryId = Number(values.category_id);
              formdata.append(
                "category_id",
                Number.isNaN(catergoryId) ? null : values.category_id
              );

              const shopId = Number(values.shop_id);
              formdata.append(
                "shop_id",
                Number.isNaN(shopId) ? null : values.shop_id
              );

              formdata.append("sort_index", 1);
              formdata.append("bestseller", isBestSeller ? 1 : 0);
              formdata.append("special", isSpecial ? 1 : 0);
              formdata.append("isactive", isActive ? 1 : 0);

              if (!hasVariant) {
                formdata.append(
                  "variations[0][id]",
                  Number(productResponse.value.variations[0].id)
                );
                formdata.append("variations[0][bio_en]", values.englishInfo);
                formdata.append("variations[0][bio_ar]", values.arabicInfo);
                formdata.append("variations[0][price]", values.price);

                formdata.append("variations[0][hasoffer]", hasOffer ? 1 : 0);
                formdata.append("variations[0][offerprice]", values.offerprice);
                formdata.append("variations[0][stocks]", values.stocks);
                formdata.append("variations[0][size_value]", null);
                formdata.append("variations[0][color_name]", null);
                formdata.append("variations[0][color_value]", null);

                // const selectedImageList =  allImageList.filter(asset=> !deletedImageIndexesList.includes(asset.id));

                const localFileList = allImageList
                  .filter((asset) => !asset.isNetworkAsset)
                  .map((asset) => asset.file);

                console.log("localAssetList", localFileList);

                localFileList.forEach((file) => {
                  formdata.append("variations[0][images][]", file);
                });

                deletedImageIndexesList.forEach((imageIndex) => {
                  formdata.append(
                    "variations[0][deleted_images][]",
                    imageIndex
                  );
                });
              } else {
                variationList.forEach((variation, index) => {
                  console.log("VARRRRRR", variation);

                  if (variation?.id != null) {
                    formdata.append(
                      `variations[${index}][id]`,
                      Number(productResponse.value.variations[index].id)
                    );
                    console.log("aaaaaa", variation);
                  }

                  formdata.append("variations[0][bio_en]", values.englishInfo);
                  formdata.append("variations[0][bio_ar]", values.arabicInfo);
                  formdata.append(
                    `variations[${index}][price]`,
                    variation.price
                  );
                  formdata.append(
                    `variations[${index}][hasoffer]`,
                    variation.hasOffer ? 1 : 0
                  );
                  formdata.append(
                    `variations[${index}][offerprice]`,
                    variation.offerprice
                  );
                  formdata.append(
                    `variations[${index}][stocks]`,
                    variation.stocks
                  );
                  formdata.append(
                    `variations[${index}][size_value]`,
                    variation.sizevalue
                  );
                  formdata.append(
                    `variations[${index}][color_name]`,
                    variation.colorName
                  );

                  formdata.append(
                    `variations[${index}][color_value]`,
                    variation.colorvalue
                  );

                  // const selectedImageList =  allImageList.filter(asset=> !deletedImageIndexesList.includes(asset.id));

                  console.log("variation.imagessss", variation.images);

                  const localFileList = variation.images
                    .filter((asset) => !asset.isNetworkAsset)
                    .map((asset) => asset.file);

                  localFileList.forEach((file) => {
                    formdata.append(`variations[${index}][images][]`, file);
                  });

                  variation.deletedImageIndexes.forEach((imageIndex) => {
                    formdata.append(
                      `variations[${index}][deleted_images][]`,
                      imageIndex
                    );
                  });

                  deletedVariationsIdList.forEach((id) => {
                    formdata.append("deletevariations[]", id);
                  });
                });
              }

              setIsCreatingOrUpdatingResponse({ loading: true });
              ProductsApi.updateProduct(formdata)
                .then((_) => history.goBack())
                .catch((e) => {
                  setIsCreatingOrUpdatingResponse({
                    loading: false,
                    success: false,
                    error: e,
                  });
                });
            }
          }
        }}
      >
        Update Product
      </button>
    );
  };

  async function toObjectUrl(url) {
    let response = fetch(url);
    let blob = await response.blob();

    console.log("URL.createObjectURL(blob);", URL.createObjectURL(blob));
    let file = await fetch(url)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], "fileNameGoesHere", { type: "image/png" })
      );

    console.log("file", file);

    // return fetch(url)
    //     .then((response)=> {
    //       return response.blob();
    //     })
    //     .then(blob=> {
    //       return URL.createObjectURL(blob);
    //     });
  }

  const renderVariationTable = () => {
    if (hasColorVariant)
      variationList.sort((a, b) => {
        return a.colorvalue.localeCompare(b.colorvalue);
      });
    return (
      <div>
        <div style={{ textAlign: "center", overflow: "auto", width: "100%" }}>
          <Table style={{ textAlign: "center", overflow: "scroll" }}>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">IMAGES</th>

                <th scope="col">PRICE</th>
                {hasSizeVariant && <th scope="col">SIZE</th>}
                {hasColorVariant && <th scope="col">COLOR</th>}
                <th scope="col">STOCKS</th>

                <th scope="col" className="d-flex justify-content-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {variationList.map((variation, index) => {
                const offerPriceStyle = {
                  color: variation.hasOffer ? "red" : "grey",
                };
                const regularPriceStyle = {
                  textDecoration: variation.hasOffer ? "line-through" : "none",
                };

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {
                        <Row className="justify-content-center">
                          {variation.images.map((asset) => {
                            return (
                              <span>
                                <Card.Img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "contain",
                                  }}
                                  src={asset.imagePath}
                                  variant="top"
                                />
                              </span>
                            );
                          })}
                        </Row>
                      }
                    </td>

                    <td>
                      <Row className="justify-content-center">
                        <p style={regularPriceStyle}> AED {variation.price}</p>

                        <span className="mx-1" />
                        {variation.hasOffer && (
                          <p style={offerPriceStyle}>
                            {" "}
                            AED {variation.offerprice}
                          </p>
                        )}
                      </Row>
                    </td>
                    {hasSizeVariant && <td>{variation.sizevalue}</td>}
                    {hasColorVariant && (
                      <td>
                        <Row className=" justify-content-center align-items-center ">
                          <div
                            style={{
                              width: "25px",
                              height: "25px",
                              background: variation.colorvalue,
                            }}
                          ></div>
                          <td>{variation.colorvalue}</td>
                        </Row>
                      </td>
                    )}
                    <td>{variation.stocks}</td>
                    <td>
                      <Row className="justify-content-center">
                        <i
                          className="fa fa-trash"
                          style={{
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={(e) => {
                            // let tempArray = [...variationList];
                            // let deletedVariation = tempArray.splice(index, 1);

                            let deletedVariation = variationList[index];

                            console.log("DELTEDVARIATION", deletedVariation);

                            if (deletedVariation.id != null) {
                              setDeletedVariationsIDList([
                                ...deletedVariationsIdList,
                                deletedVariation.id,
                              ]);
                            }

                            let tempArray = [...variationList];
                            tempArray.splice(index, 1);
                            setVariationList(tempArray);
                          }}
                        />
                        <span className="mx-2"></span>

                        <i
                          className="fa fa-pencil"
                          style={{
                            cursor: "pointer",
                            color: "blue",
                          }}
                          onClick={(e) => {
                            setSelectedVariationInfo({
                              index: index,
                              variation: variation,
                            });
                            setShowAddVariationModal(true);
                          }}
                        />

                        <span className="mx-2"></span>

                        {variation.id == null && (
                          <i
                            className="fa fa-copy"
                            style={{
                              cursor: "pointer",
                              color: "purple",
                            }}
                            onClick={async (e) => {
                              variation = new Variation(
                                null,
                                variation.product_id,
                                variation.price,
                                variation.offerprice,
                                variation.hasoffer,
                                variation.stocks,
                                variation.sizevalue,
                                variation.colorvalue,
                                variation.color_name,
                                variation.images,
                                variation.bio_en,
                                variation.bio_ar,
                                variation.colorName
                              );

                              let tempArray = [...variationList];
                              tempArray.push(variation);
                              setVariationList(tempArray);

                              //   // toObjectUrl(variation.images[0])
                              //   let blob = await fetch(variation.images[0].imagePath).then(r => r.blob());
                              //   let path = URL.createObjectURL(blob)

                              //  let file = new File([blob], "fileNameGoesHere", { type: "image/png" });
                              //   console.log("blob",blob);
                              //   let asset = new Asset(null, false, path, file);

                              //   variation.images = [asset];
                            }}
                          />
                        )}
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <ErrorMessage
          component="div"
          className="error text-danger"
          name={"variations"}
        />
      </div>
    );
  };
  console.log(
    `productResponse.success ${productResponse.success} shopListResponse.success ${shopListResponse.success} categoryListResponse.success ${categoryListResponse.success}`
  );

  return (
    <div>
      {(productResponse.loading && isEdit) ||
      shopListResponse.loading ||
      categoryListResponse.loading ||
      isCreatingOrUpdatingResponse.loading ? (
        <Loader></Loader>
      ) : (!productResponse.success && isEdit) ||
        !shopListResponse.success ||
        !categoryListResponse.success ? (
        <Message variant="danger">"Oops something went wrong!"</Message>
      ) : (
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={
            isEdit
              ? {
                  name_ar: productResponse.value.name_ar,
                  name_en: productResponse.value.name_en,
                  category_id:
                    categoryListResponse.value?.find(
                      (e) => e.id == productResponse.value?.categoryId
                    ) != null
                      ? productResponse.value.categoryId
                      : "",
                  shop_id:
                    shopListResponse.value?.find(
                      (e) => e.id == productResponse.value?.shopId
                    ) != null
                      ? productResponse.value.shopId
                      : "",
                  description_ar: productResponse.value.description_ar,
                  description_en: productResponse.value.description_en,
                  stocks: productResponse.value.variations[0].stocks,
                  price: productResponse.value.variations[0].price,
                  offerprice: productResponse.value.variations[0].offerprice,
                  images: productResponse.value.variations[0].images,
                  englishInfo: productResponse.value.variations[0].bioEn,
                  arabicInfo: productResponse.value.variations[0].bioAr,
                }
              : {
                  name_ar: "",
                  name_en: "",
                  category_id: "",
                  shop_id: "",
                  description_ar: "",
                  description_en: "",
                  stocks: "",
                  price: "",
                  offerprice: 0,
                  images: [],
                  englishInfo: "",
                  arabicInfo: "",
                }
          }
          validationSchema={validationSchema()}
          onSubmit={(values) => {
            console.log("ONSUBMIT");

            // formdata.append("");
          }}
        >
          {(formik) => {
            console.log("formik", formik);
            return (
              <Form>
                {!isCreatingOrUpdatingResponse.success && (
                  <Message variant="danger">
                    "Oops something went wrong!"
                  </Message>
                )}

                {!hasVariant && (
                  <Col>
                    {renderUploadImageButton(formik)}
                    <div className=" my-4" />

                    {renderProductImageSection(formik)}
                  </Col>
                )}

                <Row>
                  <Col>{renderArabicNameField()}</Col>

                  <Col>{renderEngLishNameField()}</Col>
                </Row>

                <Row>
                  <Col>{renderArabicDescriptionField()}</Col>
                  <Col>{renderEnglishDescriptionField()}</Col>
                </Row>

                <Row>
                  <Col>{renderEnglishInfoField()}</Col>
                  <Col>{renderArabicInfoField()}</Col>
                </Row>

                <Row>
                  <Col>{renderCategoryDropDown()}</Col>
                  <Col>{renderShopDropDown()}</Col>
                </Row>
                <div className=" my-4" />

                {!hasVariant && (
                  <Row>
                    <Col>{renderPriceField()}</Col>
                    <Col>{renderStockField()}</Col>
                  </Row>
                )}

                {hasOffer && !hasVariant && renderOfferPriceField()}

                <Col className=" mx-0">
                  <Row>{!hasVariant && renderHasOfferCheckBox()}</Row>
                  <div className=" my-4" />
                  <Row>
                    {!isEdit && (
                      <Row className=" mx-0">
                        {renderHasVariantCheckBox()}
                        <span className=" mx-4" />
                      </Row>
                    )}

                    {/* {renderIsActiveCheckBox()}
                      <span className=" mx-4" /> */}
                    {renderIsSpecialCheckBox()}
                  </Row>
                </Col>

                <Row className="float-right">
                  {hasVariant && renderAddNewVariationButton()}
                  {isEdit && renderDeleteProductButton()}
                  {isEdit ? renderUpdateButton() : renderCreateButton()}
                </Row>

                <span className=" my-5" />

                {hasVariant && renderVariationTable()}

                {showAddVariationModal && renderAddVariationModal()}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default AddProductScreenV2;
