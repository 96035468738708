import React from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../components/TextField";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { Card, Col } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getAppSettings,
  updateAppSettings
} from "../../actions/appsettingsActions";




const AppSettingsScreen = ({ match, history }) => {

  const [appSettingsImage, setAppSettingsImage] = useState(undefined);
  const [hasImageChanged, setHasImageChanged] = useState(false);

  const appSettings = useSelector((state) => state.appSettingsDetails);
  const { loading, error, appSettingsDetails } = appSettings;


  const handleSubmit = async (formdata) => {
    dispatch(updateAppSettings(dispatch, formdata));
    // dispatch(getSlider());
    history.push("/appsettings");
  };




  const handleImageChange = (e, formik) => {
    if (e.target.files) {

      var isImageSizeValid = true;
      Array.from(e.target.files).forEach((file) => {

        if (file.size / (1024 * 1024) > 2) {
          alert("Please upload a maximum of 2MB ");
          isImageSizeValid = false;
        }
      })

      if (!isImageSizeValid) {
        return;
      }
      const U = URL.createObjectURL(e.target.files[0]);
      setHasImageChanged(true);
      URL.revokeObjectURL(e.target.files);
      setAppSettingsImage(U);
    }

    formik.setFieldValue("image", e.currentTarget.files[0]);

  };






  const validate = Yup.object({
    saudishipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    omanshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    abudhabishipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    dubaishipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    sharjahshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    rasAlKhaimashipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    ummAlQuwainshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    ajmanshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    fujairahshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    westernRegionshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),
    alainshipping: Yup.number().required("Required").min(0, "Shipping fee must be greater than or equal to 0"),

    // location: Yup.string()
    //   .min(1, "Name must be atleast one character")
    //   .required("Required"),
    // aspectratio: Yup.number().required("Required"),
    // image:
    //   Yup.mixed().required("required") || Yup.string().required("required"),
  });

  const dispatch = useDispatch();
  // useEffect(() => {
  //   useDispatch(getAppSettings)
  // },[])

  useEffect(() => {
    if (appSettingsDetails) {
      setAppSettingsImage([appSettingsDetails.categoryAllImg]);

    }
  }, [appSettingsDetails]);


  useEffect(() => {
    dispatch(getAppSettings())
  }, [dispatch]);













  return (
    <>{
      loading ?
        <Loader /> :
        error ?
          <Message variant="danger">{error}</Message> :
          // <Message variant="danger">{error || errorcreate}</Message>

          <Formik
            enableReinitialize
            initialValues={{
              saudishipping: appSettingsDetails.saudiDeliveryFee || 0,
              omanshipping: appSettingsDetails.omanDeliveryFee || 0,
              abudhabishipping: appSettingsDetails.abuDhabiDeliveryFee || 0,
              dubaishipping: appSettingsDetails.dubaiDeliveryFee,
              sharjahshipping: appSettingsDetails.sharjahDeliveryFee,
              rasAlKhaimashipping: appSettingsDetails.rasAlKhaimaiDeliveryFee,
              ummAlQuwainshipping: appSettingsDetails.ummAlQuwainDeliveryFee,
              ajmanshipping: appSettingsDetails.ajmanDeliveryFee,
              fujairahshipping: appSettingsDetails.fujairahDeliveryFee,
              westernRegionshipping: appSettingsDetails.westernRegionDeliveryFee,
              alainshipping: appSettingsDetails.alAinDeliveryFee,
              image: "",
              // location: (slider && slider.location) || "",
              // aspectratio: (slider && slider.aspectratio) || "",
              // image: (slider && slider.fullurl) || "",
              // shop_id: (slider && slider.shop_id) || "",
              // product_id: (slider && slider.pid) || "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              console.log("SUBMIT");

              /*if (categoryId) {
                if (checkPermissionOnSubmit("category.update")) {
                  history.push("/error");
                  return;
                }
              }*/

              let formdata = new FormData();


              // if (sliderId) {
              //   formdata.append("id", sliderId);
              // }



              formdata.append("delivery_fee_ksa", values.saudishipping);
              formdata.append("delivery_fee_oman", values.omanshipping);
              formdata.append("delivery_fee_abudhabi", values.abudhabishipping);
              formdata.append("delivery_fee_dubai", values.dubaishipping);
              formdata.append("delivery_fee_sharjah", values.sharjahshipping);
              formdata.append("delivery_fee_rak", values.rasAlKhaimashipping);
              formdata.append("delivery_fee_umm", values.ummAlQuwainshipping);
              formdata.append("delivery_fee_ajman", values.ajmanshipping);
              formdata.append("delivery_fee_fujairah", values.fujairahshipping);
              formdata.append("delivery_fee_western", values.westernRegionshipping);
              formdata.append("delivery_fee_alain", values.alainshipping);
              formdata.append("delivery_fee_uae", 0);





              if (hasImageChanged) {
                if (typeof values.image === "string") {
                  formdata.delete("image");
                } else {
                  formdata.append("image", values.image);
                }
              }


              handleSubmit(formdata);
            }}
          >
            {(formik) => (

              <div className="row">

                <div className="col-md-4">
                  <div>
                    <Card
                      className="my-2 p-1 rounded"
                      style={{ height: "280px", objectFit: "cover" }}
                    >{console.log(appSettingsImage)}
                      <Card.Img
                        style={{ height: "270px", objectFit: "contain" }}
                        src={appSettingsImage}
                        variant="top"
                      />
                    </Card>
                    <div className="d-flex my-2 ">
                      <label className="custom-file-upload w-100">
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(e, formik)}
                          name="image"
                          accept="image/png, image/jpeg, image/jpg"
                        />
                        <ErrorMessage
                          component="div"
                          className="error text-danger"
                          name="image"
                        />
                        <i className="bx bx-cloud-upload mx-2"></i>Upload
                        All Items Image
                      </label>
                    </div>
                  </div>

                </div>


                <div className="col-md-6">
                  <Form>
                    <h3>Shipping Fees</h3>
                    <div style={{padding:"15px 0"}}></div>

                    <h4>UAE</h4>
                    <div className="row">
                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Abu Dhabi"
                          name="abudhabishipping"
                          type="number"
                          min="0"
                        />
                      </div>
                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Dubai"
                          name="dubaishipping"
                          type="number"
                          min="0"
                        />
                      </div>
                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Sharjah"
                          name="sharjahshipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Ras Al Khaimah"
                          name="rasAlKhaimashipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Umm Al Quwain"
                          name="ummAlQuwainshipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Ajman"
                          name="ajmanshipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Fujairah"
                          name="fujairahshipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Al Ain"
                          name="alainshipping"
                          type="number"
                          min="0"
                        />
                      </div>

                      <div className="col-md-6 col-sm-4">
                        <TextField
                          label="Western Region"
                          name="westernRegionshipping"
                          type="number"
                          min="0"
                        />
                      </div>



                    </div>

                    <div style={{padding:"15px 0"}}></div>

                    <h4>Saudi Arabia</h4>
                    <div className="row">
                      <div className="col-md-12 col-sm-8">
                        <TextField
                          label="All"
                          name="saudishipping"
                          type="number"
                          min="0"
                        />
                      </div>
                    </div>

                    <div style={{padding:"15px 0"}}></div>


                    <h4>Oman</h4>
                    <div className="row">
                      <div className="col-md-12 col-sm-8">
                        <TextField
                          label="All"
                          name="omanshipping"
                          type="number"
                          min="0"
                        />
                      </div>
                    </div>
                    <button className="btn btn-success mt-3 my-2" type="submit">
                      Save
                  </button>
                  </Form>
                </div>
              </div>
            )}
          </Formik>}
    </>
  );
};

export default AppSettingsScreen;


