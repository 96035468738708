export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DETAILS_LIST_REQUEST = "ORDER_DETAILS_LIST_REQUEST";
export const ORDER_DETAILS_LIST_SUCCESS = "ORDER_DETAILS_LIST_SUCCESS";
export const ORDER_DETAILS_LIST_FAIL = "ORDER_DETAILS_LIST_FAIL";


export const ORDERSTATUS_REQUEST = "ORDERSTATUS_REQUEST";
export const ORDERSTATUS_SUCCESS = "ORDERSTATUS_SUCCESS";
export const ORDERSTATUS_FAIL = "ORDERSTATUS_FAIL";

