import Product from "../models/productmodel";
import Variation from "../models/variationmodel";
import Shop from "../models/shopmodel";
import { BASE_URL } from "../constants/Globals";
import axios, { AxiosRequestConfig } from "axios";


class ProductsApi {

    static async getProductDetail(productid: number): Promise<Product> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
            },

        };

        let product: Product
        try {
            const response = await axios.get(
                `${BASE_URL}api/v2/admin/product/${productid}}`,
                config
            );
            product = Product.fromJson(response.data.data[0])
        } catch (e) {
            console.log("Error when optaining product detail: ", e);
            throw e.toString();
        }

        return product;
    }



    static async createProduct(formData: FormData): Promise<void> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
            },

        };

        let product: Product
        try {
            const response = await axios.post(
                `${BASE_URL}api/v2/admin/product`,
                formData,
                config
            );
            // ddod

            console.log("RESPONSE FROM CREATE PRODUCT ", response)
        } catch (e) {
            console.log("Error when creating product: ", e);
            throw e.toString();
        }

    }


    static async updateProduct(formData: FormData): Promise<void> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
            },

        };

        let product: Product
        try {
            const response = await axios.post(
                `${BASE_URL}api/v2/admin/product`,
                formData,
                config
            );
            // ddod

            console.log("RESPONSE FROM UPDATE PRODUCT ", response)
        } catch (e) {
            console.log("Error when UPDATING product: ", e);
            throw e.toString();
        }

    }





    static async deleteProduct(id: number): Promise<void> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
            },

        };

        const formData = new FormData();

        // @ts-ignore
        formData.append("product_id",id);
        // formData.append("isactive", 0);






        try {
            const response = await axios.post(
                `${BASE_URL}api/v2/admin/activeprod`,
                formData,
                config
            );

            console.log("RESPONSE FROM delete PRODUCT ", response)
        } catch (e) {
            console.log("Error when deleting product: ", e);
            throw e.toString();
        }

    }








}


export default ProductsApi;
