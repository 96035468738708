import React from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  getCategory,
  listCategoryDetails,
} from "../../actions/categoryActions";
import * as Yup from "yup";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { ErrorMessage, Form, Formik } from "formik";
import { Card, Col } from "react-bootstrap";
import TextField from "../components/TextField";
import checkPermission, { checkPermissionOnSubmit } from "./checkpermission";
import {
  createSlider,
  getSlider,
  listSliderDetails,
} from "../../actions/sliderActions";
import { listProducts } from "../../actions/productActions";
import { getAllShops, listShops } from "../../actions/shopActions";
import { BASE_URL } from "../../constants/Globals";
import axios from "axios";
import Select from 'react-select';


import AsyncSelect from 'react-select/async';
import debounce from "debounce-promise";


const AddNewSliderScreen = ({ match, history }) => {
  const [sliderImage, setSliderImage] = useState([]);
  const [top, setTop] = useState({ checked: false });
  const [bottom, setBottom] = useState({ checked: false });

  const sliderId = match.params.id;

  const sliderDetails = useSelector((state) => state.sliderDetails);
  const { loading, error, sliderDetails: slider } = sliderDetails;

  // console.log("sliderDetails",sliderDetails);

  const [selectedOption, setSelectedOption] = useState("");
  const [productSearchTimeOut, setProductSearchTimeOut] = useState("");


  const productList = useSelector((state) => state.productList);
  const {
    loading: productloading,
    error: producterror,
    products,
    page,
    pages,
  } = productList;

  const allshops = useSelector((state) => state.allshops);
  const { loading: shoploading, shopError, shops } = allshops;

  const populateOptions = () => {
    let objects = [products.length];
    for (var x = 0; x < products.length; x++) {
      objects[x] = { value: products[x].id, label: products[x].name_en };
    }
    // console.log(objects);

    return objects;
  };



  const populateShops = () => {


    let objects = shops.map(shop => {
      return {
        "label": shop.shop_name_en,
        "value": shop.id
      }
    })


    objects.unshift({ label: "Choose a shop", value: "" });

    return objects;

  };



  const populateshopOptions = () => {
    let objects = [shops.length];
    for (var x = 0; x < shops.length; x++) {
      objects[x] = { value: shops[x].id, label: shops[x].shop_name_en };
    }
    // console.log(objects);
    return objects;
  };



  const sliderCreate = useSelector((state) => state.sliderCreate);
  const { loading: loadingcreate, error: errorcreate } = sliderCreate;

  const dispatch = useDispatch();

  const handleImageChange = (e, formik) => {
    if (e.target.files) {
      var isImageSizeValid = true;
      Array.from(e.target.files).forEach((file) => {

        if (file.size / (1024 * 1024) > 2) {
          alert("Please upload a maximum of 2MB ");
          isImageSizeValid = false;
        }
      })

      if (!isImageSizeValid) {
        return;
      }
      const U = URL.createObjectURL(e.target.files[0]);
      setSliderImage(U);
      URL.revokeObjectURL(e.target.files);
    }

    formik.setFieldValue("image", e.currentTarget.files[0]);
  };

  useEffect(() => {
    if (slider) {
      setSliderImage(slider.fullurl);
      if (slider.location === "top") {
        setTop({ checked: true });
      } else {
        setTop({ checked: false });
      }
      if (slider.location === "bottom") {
        setBottom({ checked: true });
      } else {
        setBottom({ checked: false });
      }
    }
  }, [slider]);

  useLayoutEffect(() => {
    //checkPermission(history, "category.add");
    dispatch(listProducts);
    dispatch(getAllShops());
    dispatch(listSliderDetails(sliderId));
    populateOptions();
  }, [dispatch, sliderId]);

  const validate = Yup.object({
    location: Yup.string()
      .min(1, "Name must be atleast one character")
      .required("Required"),
    aspectratio: Yup.number().required("Required"),
    image:
      Yup.mixed().required("required") || Yup.string().required("required"),
  });

  const handleSubmit = async (formdata) => {
    dispatch(createSlider(dispatch, formdata));

    dispatch(getSlider());
    history.push("/sliders");
  };



  const searchProducts = (resolve, inputValue) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };
    axios.get(
      `${BASE_URL}api/v2/public/searchprodandshops?search=${inputValue}`,
      config
    ).then(value => {
      // console.log(value.data.products);

      let objects = value.data.products.map(product => {
        return {
          value: product.id,
          label: `${product.id}: ${product.name}`
        };
      })
      resolve(objects)
    }).catch(e => { resolve([]); });

  }

  const productOptions = (inputValue) => {

    return new Promise((resolve) => {

      searchProducts(resolve, inputValue)


      // if(productSearchTimeOut){
      //   clearTimeout(productSearchTimeOut);
      // }
      // var myfunc = setTimeout(searchProducts(resolve, inputValue), 1000)
      // setProductSearchTimeOut(myfunc);




    });

  }


  const searchShops = (resolve, inputValue) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    axios.get(
      `${BASE_URL}api/v2/admin/adminsearch?search=${inputValue}&type=shops`,
      config
    ).then(value => {
      // console.log(value);
      resolve(value.data.map(shop => {
        return {
          value: shop.id,
          label: shop.shop_name_en
        };
      }))
    }).catch(e => { resolve([]); });

  }


  const shopOptions = (inputValue) => {
    return new Promise((resolve) => {
      searchShops(resolve, inputValue);
    });

  }

  return (
    <>
      {loading || loadingcreate ? (
        <Loader />
      ) : error || errorcreate ? (
        <Message variant="danger">{error || errorcreate}</Message>
      ) : (
            <Formik
              enableReinitialize
              initialValues={{
                location: (slider!=null)? slider.location : "",
                aspectratio:  (slider!=null)?  slider.aspectratio:  "",
                image: (slider!=null)?  slider.fullurl :"",
                shop_id:(slider!=null)?  slider.shop_id :"",
                product_id:  (slider!=null)?  slider.pid: "",
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                /*if (categoryId) {
                  if (checkPermissionOnSubmit("category.update")) {
                    history.push("/error");
                    return;
                  }
                }*/

                let formdata = new FormData();


                if (sliderId) {
                  formdata.append("id", sliderId);
                }



                formdata.append("location", values.location);
                formdata.append("aspectratio", values.aspectratio);

                formdata.append("location", values.location);

                if (typeof values.image === "string") {
                  formdata.delete("file");
                } else {
                  formdata.append("file", values.image);
                }

               if(values.product_id!=null) formdata.append("pid", values.product_id);
               if(values.shop_id!=null)formdata.append("shop_id", values.shop_id);

                handleSubmit(formdata);
                console.log("form: ", formdata)
              }}
            >
              {(formik) => (
                <div className="row">
                  <div className="col-md-12">
                    {sliderId ? (
                      <div>
                        <Card
                          className="my-2 p-1 rounded"
                          style={{ height: "280px", objectFit: "cover" }}
                        >
                          <Card.Img
                            style={{ height: "270px", objectFit: "contain" }}
                            src={sliderImage}
                            variant="top"
                          />
                        </Card>

                        <div className="d-flex my-2 ">
                          <label className="custom-file-upload w-100">
                            <input
                              type="file"
                              onChange={(e) => handleImageChange(e, formik)}
                              name="image"
                              accept="image/png, image/jpeg, image/jpg"
                            />
                            <ErrorMessage
                              component="div"
                              className="error text-danger"
                              name={"image"}
                            />
                            <i className="bx bx-cloud-upload mx-2"></i>Upload Slider
                        Image
                      </label>
                        </div>
                      </div>
                    ) : (
                        <div>
                          <Card
                            className="my-2 p-1 rounded"
                            style={{ height: "280px", objectFit: "cover" }}
                          >
                            <Card.Img
                              style={{ height: "270px", objectFit: "contain" }}
                              src={sliderImage}
                              variant="top"
                            />
                          </Card>

                          <div className="d-flex my-2 ">
                            <label className="custom-file-upload w-100">
                              <input
                                type="file"
                                onChange={(e) => handleImageChange(e, formik)}
                                name="image"
                                accept="image/png, image/jpeg, image/jpg"
                              />
                              <ErrorMessage
                                component="div"
                                className="error text-danger"
                                name={"image"}
                              />
                              <i className="bx bx-cloud-upload mx-2"></i>Upload Slider
                        Image
                      </label>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-md-12">
                    <Form>
                      <div className="row g-3">
                        <div className="col-md-4">
                          <TextField
                            label="Aspect Ratio"
                            name="aspectratio"
                            type="number"
                          />
                        </div>
                        {/*<option value="">Select a state...</option>*/}

                        <div className="col-md-4 h-auto" style={{ display: 'flex', alignItem: 'center', justifyContent: 'flex-start', gap: '10px' }}>
                          <div style={{ width: "100%" }} >
                            <label>Link To Product</label>
                            <AsyncSelect
                              loadOptions={debounce(productOptions, 1000)}
                              defaultValue={slider.product != null ? { value: slider.product?.id, label: slider.product?.name_en } : {label:"Choose a product", value:null}}
                              name="pid"
                              placeholder="Search Product"

                              onChange={(e) => {
                                // console.log(e);
                                formik.setFieldValue("product_id", e.value);
                              }}
                            />
                          </div>
                        </div>


                        <div className="col-md-4">
                          <label>Link To Shop</label>
                          <Select
                            // options={populateshopOptions()}
                            // loadOptions={debounce(shopOptions,1000)}
                            options={
                              populateShops()}
                            defaultValue={(slider.shop != null) ? { value: slider.shop?.id, label: slider.shop?.shop_name_en } : {label:"Choose a Shop", value:null}}
                            name="shop_id"
                            placeholder="Search Shop"
                            onChange={(e) => {
                              formik.setFieldValue("shop_id", e.value);
                            }}
                          />
                        </div>
                        {/* </div> */}


                        {/* <div className="col-md-4 h-auto">
                      <label>Link To Product</label>
                      <Select
                        options={populateOptions()}
                        name="pid"
                        placeholder="Search Product"
                        onChange={(e) => {
                          formik.setFieldValue("product_id", e.value);
                        }}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Link To Shop</label>
                      <Select
                        options={populateshopOptions()}
                        name="shop_id"
                        placeholder="Search Shop"
                        onChange={(e) => {
                          formik.setFieldValue("shop_id", e.value);
                        }}
                        
                      />
                    </div> */}
                      </div>

                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={top.checked}
                          onChange={(d) => {
                            if (top.checked === true) {
                              d = false;
                              formik.setFieldValue("location", "bottom");
                              setBottom({ checked: true });
                              setTop({ checked: false });
                            } else {
                              d = true;
                              formik.setFieldValue("location", "top");
                              setBottom({ checked: false });
                              setTop({ checked: true });
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Top
                    </label>
                      </div>

                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={bottom.checked}
                          onChange={(d) => {
                            if (bottom.checked === true) {
                              d = false;
                              formik.setFieldValue("location", "top");
                              setBottom({ checked: false });
                              setTop({ checked: true });
                            } else {
                              d = true;
                              formik.setFieldValue("location", "bottom");
                              setBottom({ checked: true });
                              setTop({ checked: false });
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Bottom
                    </label>
                      </div>
                      <button className="btn btn-success mt-3 my-2" type="submit">
                        Save
                  </button>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          )}
    </>
  );
};

export default AddNewSliderScreen;


// import React from "react";
// import { useLayoutEffect } from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import debounce from "lodash.debounce";

// // import { BASE_URL } from "src/constants/Globals";
// import {
//   createCategory,
//   getCategory,
//   listCategoryDetails,
// } from "../../actions/categoryActions";
// import * as Yup from "yup";
// import Loader from "../components/Loader";
// import Message from "../components/Message";
// import { ErrorMessage, Form, Formik } from "formik";
// import { Card, Carousel, Col } from "react-bootstrap";
// import TextField from "../components/TextField";
// import checkPermission, { checkPermissionOnSubmit } from "./checkpermission";
// import {
//   createSlider,
//   getSlider,
//   getSliderDetails,
// } from "../../actions/sliderActions";
// import { listProducts } from "../../actions/productActions";
// import Select from "react-select";
// import AsyncSelect from 'react-select/async';
// import { getAllShops, listShops } from "../../actions/shopActions";
// import { BASE_URL } from "../../constants/Globals";

// const AddNewSliderScreen = ({ match, history, location, isEdit }) => {

//   console.log("isEdit", isEdit);
//   const [sliderImage, setSliderImage] = useState([]);
//   const [productSearchTimeOut, setProductSearchTimeOut] = useState(undefined);

//   const [top, setTop] = useState({ checked: false });
//   const [bottom, setBottom] = useState({ checked: false });
//   // const [sliders, setSliders] = useState([]);


//   const sliderId = match.params.id;

//   const sliderDetails = useSelector((state) => state.sliderDetails);
//   const { loading, error, sliderDetails: slider } = sliderDetails;

//   const sliderList = useSelector((state) => state.sliderList);




//   const [sliders, setSliders] = useState((isEdit) ? (sliderId == 1 ? sliderList.sliders.top : sliderList.sliders.bottom).map(e => { return e.fullurl; }) : ([]));
//   // var sliders = sliderId == 1 ? sliderList.sliders.top : sliderList.sliders.bottom;
//   console.log("sliders", sliders);



//   const [selectedOption, setSelectedOption] = useState("");

//   const productList = useSelector((state) => state.productList);
//   const {
//     loading: productloading,
//     error: producterror,
//     products,
//     page,
//     pages,
//   } = productList;

//   const allshops = useSelector((state) => state.allshops);
//   const { loading: shoploading, shopError, shops } = allshops;

//   const populateOptions = () => {
//     let objects = [products.length];
//     for (var x = 0; x < products.length; x++) {
//       objects[x] = { value: products[x].id, label: products[x].name_en };
//     }
//     // console.log(objects);

//     return objects;
//   };

//   function onDeleteSliderItem(index) {
//     // setSliders(sliders.filter((e,i)=>i!=index));


//   }



//   const populateshopOptions = () => {
//     let objects = [shops.length];
//     for (var x = 0; x < shops.length; x++) {
//       objects[x] = { value: shops[x].id, label: shops[x].shop_name_en };
//     }
//     console.log(objects);
//     return objects;
//   };



//   const sliderCreate = useSelector((state) => state.sliderCreate);
//   const { loading: loadingcreate, error: errorcreate } = sliderCreate;

//   const dispatch = useDispatch();

//   const handleImageChange = (e, formik) => {
//     if (e.target.files) {

//       var isImageSizeValid = true;



//       Array.from(e.target.files).forEach((file) => {

//         if (file.size / (1024 * 1024) > 2) {
//           alert("Please upload a maximum of 2MB ");
//           isImageSizeValid = false;
//         }
//       })

//       if (!isImageSizeValid) {
//         return;
//       }
//       const U = URL.createObjectURL(e.target.files[0]);
//       setSliderImage(U);
//       URL.revokeObjectURL(e.target.files);

//       console.log(U);

//       setSliders([...sliders, U]);

//     }

//     formik.setFieldValue("images", e.currentTarget.files);

//   };


//   const searchProducts = (resolve, inputValue)=>{
//     const userInfo = JSON.parse(localStorage.getItem("userInfo"));
//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${userInfo.success.token}`,
//       },
//     };
//     axios.get(
//       `${BASE_URL}api/v2/public/searchprodandshops?search=${inputValue}`,
//       config
//     ).then(value => {
//       console.log(value.data.products);
//       resolve(value.data.products.map(product => {
//         return {
//           value: product.id,
//           label: product.name
//         };
//       }))
//     }).catch(e => { resolve([]); });

//   }

//   const productOptions = (inputValue) => {
//     return new Promise((resolve) => {


//       if(productSearchTimeOut){

//         clearTimeout(productSearchTimeOut);

//       }
//       var myfunc = setTimeout(searchProducts(resolve, inputValue), 1000)
//       setProductSearchTimeOut(myfunc);

//       // setTimeout(() => {
//       //   resolve(filterColors(inputValue));
//       // }, 1000);
//     });

//   }


//   const searchShops = (resolve, inputValue)=>{
//     const userInfo = JSON.parse(localStorage.getItem("userInfo"));
//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${userInfo.success.token}`,
//       },
//     };

// axios.get(
//       `${BASE_URL}api/v2/admin/adminsearch?search=${inputValue}&type=shops`,
//       config
//     ).then(value => {
//       console.log(value);
//       resolve(value.data.map(shop => {
//         return {
//           value: shop.id,
//           label: shop.shop_name_en
//         };
//       }))
//     }).catch(e => { resolve([]); });

//   }


//   const shopOptions = (inputValue) => {
//     return new Promise((resolve) => {
//       searchShops(resolve,inputValue);
//     });

//   }




//   useEffect(() => {
//     if (slider) {
//       setSliderImage(slider.fullurl);
//       if (slider.location === "top") {
//         setTop({ checked: true });
//       } else {
//         setTop({ checked: false });
//       }
//       if (slider.location === "bottom") {
//         setBottom({ checked: true });
//       } else {
//         setBottom({ checked: false });
//       }
//     } else {

//       if (sliderId == 1) {

//         setTop({ checked: true });
//       } else {
//         setBottom({ checked: true });
//       }


//     }
//   }, [slider]);



//   // useEffect(()=>{

//   //   setSliders(unmappedsliders.map(e=>{
//   //     return  e.fullurl
//   //   }));
//   // },[unmappedsliders])

//   useLayoutEffect(() => {
//     //checkPermission(history, "category.add");
//     if (isEdit) {
//       dispatch(listProducts);
//       dispatch(getSliderDetails(sliderId));
//     }
//     dispatch(getAllShops());
//     populateOptions();
//   }, [dispatch, sliderId]);

//   const validate = Yup.object({

//     aspectratio: Yup.number().required("Required").positive(),
//     images:
//       Yup.mixed().required("required") || Yup.string().required("required"),
//   });

//   const handleSubmit = async (formdata) => {
//     console.log(formdata);
//     console.log("ABOUT TO CALL THE API");
//     dispatch(createSlider(dispatch, formdata));

//     dispatch(getSlider());
//     history.push("/sliders");
//   };




//   return (
//     <>
//       {loading || loadingcreate ? (
//         <Loader />
//       ) : error || errorcreate ? (
//         <Message variant="danger">{error || errorcreate}</Message>
//       ) : (
//             <Formik
//               enableReinitialize
//               initialValues={{
//                 location: (slider && slider.location) || "",
//                 aspectratio: (slider && slider.aspectratio) || 2,
//                 image: (slider && slider.fullurl) || "",
//                 shop_id: (slider && slider.shop_id) || "",
//                 product_id: (slider && slider.pid) || "",
//               }}
//               validationSchema={validate}
//               onSubmit={(values) => {
//                 console.log("SUBMIT");
//                 console.log(values);


//                 /*if (categoryId) {
//                   if (checkPermissionOnSubmit("category.update")) {
//                     history.push("/error");
//                     return;
//                   }
//                 }*/

//                 let formdataInfo = new FormData();

//                 // if (sliderId) {
//                 //   // formdataInfo.append("id", sliderId);
//                 // }z
//                 formdataInfo.append("aspectratio", values.aspectratio);

//                 formdataInfo.append("location", sliderId == 1 ? 'top' : 'bottom');

//                 if (typeof values.images === "string") {
//                   formdataInfo.delete("file");
//                 } else {
//                   console.log(values.images);
//                   formdataInfo.append("file", values.images[0]);
//                 }
//                 formdataInfo.append("pid", values.product_id);
//                 formdataInfo.append("shop_id", values.shop_id);
//                 handleSubmit(formdataInfo);

//               }}
//             >
//               {(formik) => (
//                 <div className="row">
//                   <div className="col-md-12">
//                     {sliderId ? (

//                       <div>
//                         {/* {console.log("sliderdetails: ", slider)} */}

//                         <Carousel
//                           className="my-2 p-1 rounded"
//                           style={{ objectFit: "cover" }}

//                         >
//                           {
//                             sliders.map((e, index) => {
//                               return <Carousel.Item>
//                                 <div style={{ position: "relative", width: "100%", }}>
//                                   {/* <img
//                                     className="d-block w-100"
//                                     src={e.fullurl}
//                                   /> */}
//                                   <Card
//                                     className="my-2 p-1 rounded"
//                                     style={{ height: "280px", objectFit: "cover" }}
//                                   >
//                                     <Card.Img
//                                       style={{ height: "270px", objectFit: "contain" }}
//                                       src={e}
//                                       variant="top"
//                                     />
//                                   </Card>

//                                   {/* <box-icon
//                                     style={{ position: "absolute", top: "0", right: "0px", width: "100px", height: "100px", color: "red", }}
//                                     onClick={onDeleteSliderItem(index)}
//                                     name='x' color="red" ></box-icon> */}
//                                 </div>
//                               </Carousel.Item>
//                             })
//                           }


//                         </Carousel>
//                         {sliders.length <= 0 && <Card
//                           className="my-2 p-1 rounded"
//                           style={{ height: "280px", objectFit: "cover" }}
//                         >
//                           {/* <Card.Img
//                             style={{ height: "270px", objectFit: "contain" }}
//                             src={ }
//                             variant="top" */}
//                           {/* /> */}
//                         </Card>}

//                         {!isEdit && <div className="d-flex my-2 ">
//                           <label className="custom-file-upload w-100">
//                             <input
//                               type="file"
//                               onChange={(e) => handleImageChange(e, formik)}
//                               accept="image/png, image/jpeg, image/jpg"
//                               name="image"
//                             />
//                             <ErrorMessage
//                               component="div"
//                               className="error text-danger"
//                               name={"image"}
//                             />
//                             <i className="bx bx-cloud-upload mx-2"></i>Upload Slider
//                         Image
//                       </label>
//                         </div>}
//                       </div>
//                     ) : (
//                         <div>
//                           <Card
//                             className="my-2 p-1 rounded"
//                             style={{ height: "280px", objectFit: "cover" }}
//                           >
//                             <Card.Img
//                               style={{ height: "270px", objectFit: "contain" }}
//                               src={sliderImage}
//                               variant="top"
//                             />
//                           </Card>

//                           <div className="d-flex my-2 ">
//                             <label className="custom-file-upload w-100">
//                               <input
//                                 type="file"
//                                 onChange={(e) => handleImageChange(e, formik)}
//                                 accept="image/png, image/jpeg, image/jpg"
//                                 name="image"
//                               />
//                               <ErrorMessage
//                                 component="div"
//                                 className="error text-danger"
//                                 name={"image"}
//                               />
//                               <i className="bx bx-cloud-upload mx-2"></i>Upload Slider
//                         Image
//                       </label>
//                           </div>
//                         </div>
//                       )}
//                   </div>

//                   <div className="col-md-12">
//                     <Form>
//                       <div className="row g-3">
//                         <div className="col-md-4">
//                           <TextField
//                             label="Aspect Ratio"
//                             name="aspectratio"
//                             type="number"
//                           />
//                         </div>
//                         {/*<option value="">Select a state...</option>*/}

//                         <div className="col-md-4 h-auto" style={{ display: 'flex', alignItem: 'center', justifyContent: 'flex-start', gap: '10px' }}>
//                           <div style={{ width: "100%" }} >
//                             <label>Link To Product</label>
//                             <AsyncSelect
//                               // options={populateOptions()}
//                               loadOptions={productOptions}
//                               name="pid"
//                               placeholder="Search Product"

//                               onChange={(e) => {
//                                 console.log(e);
//                                 formik.setFieldValue("product_id", e.value);
//                               }}
//                             />
//                           </div>
//                         </div>


//                         <div className="col-md-4">
//                           <label>Link To Shop</label>
//                           <AsyncSelect
//                             // options={populateshopOptions()}
//                             loadOptions={shopOptions}
//                             name="shop_id"
//                             placeholder="Search Shop"
//                             onChange={(e) => {
//                               formik.setFieldValue("shop_id", e.value);
//                             }}
//                           />
//                         </div>
//                       </div>

//                       <div class="form-check form-switch">
//                         <input
//                           class="form-check-input"
//                           type="checkbox"
//                           disabled={true}
//                           id="flexSwitchCheckDefault"
//                           checked={top.checked}
//                         // onChange={(d) => {
//                         //   if (top.checked === true) {
//                         //     d = false;
//                         //     formik.setFieldValue("location", "bottom");
//                         //     setBottom({ checked: true });
//                         //     setTop({ checked: false });
//                         //   } else {
//                         //     d = true;
//                         //     formik.setFieldValue("location", "top");
//                         //     setBottom({ checked: false });
//                         //     setTop({ checked: true });
//                         //   }
//                         // }}
//                         />
//                         <label
//                           class="form-check-label"
//                           for="flexSwitchCheckDefault"
//                         >
//                           Top
//                     </label>
//                       </div>

//                       <div class="form-check form-switch">
//                         <input
//                           class="form-check-input"
//                           type="checkbox"
//                           disabled={true}
//                           id="flexSwitchCheckDefault"
//                           checked={bottom.checked}
//                         // onChange={(d) => {
//                         //   if (bottom.checked === true) {
//                         //     d = false;
//                         //     formik.setFieldValue("location", "top");
//                         //     setBottom({ checked: false });
//                         //     setTop({ checked: true });
//                         //   } else {
//                         //     d = true;
//                         //     formik.setFieldValue("location", "bottom");
//                         //     setBottom({ checked: true });
//                         //     setTop({ checked: false });
//                         //   }
//                         // }}
//                         />
//                         <label
//                           class="form-check-label"
//                           for="flexSwitchCheckDefault">
//                           Bottom
//                     </label>
//                       </div>
//                       <button className="btn btn-success mt-3 my-2" type="submit">
//                         Save
//                   </button>
//                     </Form>
//                   </div>
//                 </div>
//               )}
//             </Formik>
//           )}
//     </>
//   );
// };



// export default AddNewSliderScreen;

