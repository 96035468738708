import React, { useLayoutEffect, useState,useEffect } from "react";
import { Badge, Card, Dropdown, Nav, Pagination, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listOrders, listOrderByStatus, updateOrderStatus } from "../../actions/orderActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import checkPermission from "./checkpermission";
import 'boxicons';
import "./login.css";


const OrdersScreen = ({ match, history }) => {

  const [currentOrderStatus, setCurrentOrderStatus] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, page, pages } = orderList;
  const [isFirstRun, setIsFirstRun] = useState(true);


  const orderStatusUpdate = useSelector((state) => state.orderStatusUpdate);
  const { loading: loadingStatusUpdate, error: errorStatusUpdate } =
    orderStatusUpdate;
  // let pageNumber = match.params.pageNumber || 1;
  let items = [];
  const user = JSON.parse(localStorage.getItem("userInfo"));



  const paginationClicked = async (number) => {
    setPageNumber(number)
    // dispatch(listOrders(pageNumber));
    // history.push(`/orders/page/${number}`);
  };

  useLayoutEffect(() => {


    if (currentOrderStatus == null) {
      dispatch(listOrders(pageNumber));
    } else {
      dispatch(listOrderByStatus(currentOrderStatus, pageNumber));
    }
  }, [pageNumber]);

  useLayoutEffect(() => {


    if (!isFirstRun) {

    if(pageNumber != 1){

      setPageNumber(1)
    }else{

      if (currentOrderStatus == null) {
        dispatch(listOrders(pageNumber));
      } else {
        dispatch(listOrderByStatus(currentOrderStatus, pageNumber));
      }

    }

    }

  }, [currentOrderStatus]);



  useEffect(() => {setIsFirstRun(false) }, [])



  function calulatePaginationItems() {
    let pageItems = [];
    let previousPage = page - 1;
    let nextPage = page + 1;
    let currentPage = page;

    pageItems.push(<Pagination.Item onClick={() => paginationClicked(1)}>
      {'<<'}
    </Pagination.Item>);

    if (previousPage > 0) {
      pageItems.push(
        <Pagination.Item onClick={() => paginationClicked(previousPage)} >{'<'}</Pagination.Item>
      );
    }

    let paginationLength = 17;

    let lowerLimit = Math.max(currentPage - Math.floor(paginationLength / 2), 1);
    let upperLimit = Math.min(currentPage + Math.floor(paginationLength / 2), pages);
    let totalLength = upperLimit - lowerLimit + 1;

    while (totalLength < paginationLength && totalLength < pages) {
      let lowerRange = currentPage - lowerLimit;
      let upperRange = upperLimit - currentPage;

      if (lowerRange < upperRange) {
        upperLimit++;
      } else {
        lowerLimit--;
      }
      totalLength++;

    }

    for (let number = lowerLimit; number <= upperLimit; number++) {
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={(event) => paginationClicked(number)}
        >
          {number}
        </Pagination.Item>
      );
    }



    if (nextPage < pages) {
      pageItems.push(
        <Pagination.Item onClick={() => paginationClicked(nextPage)}> {'>'} </Pagination.Item>
      );
    }

    pageItems.push(
      <Pagination.Item onClick={() => paginationClicked(pages)} >{'>>'} </Pagination.Item>
    );

    return pageItems;
  }

  items = calulatePaginationItems();


  function buildDropDown() {

    return (
      <Dropdown
        style={{
          position: "relative",
          marginBottom: "10px",
        }}
        onClick={() => {
          checkPermission(history, "orders.update");
        }}
      >
        <div>
          <Dropdown.Toggle
            variant=""
            className="table-dropdown icon-false"
            style={{
              position: "relative",
              padding: "0px", margin: "0px"

            }}
          >
            <i class='bx bxs-down-arrow' style={{ padding: "0px", margin: "0px" }}></i>
            {/* <box-icon type='solid' name='down-arrow' size ='xs' style={{padding:"0 px", margin:"0 px"}}></box-icon> */}
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu>
          <h6 style={{ margin: "10px" }}>Filter by:</h6>


          <Dropdown.Item
            onClick={() => {

              setCurrentOrderStatus(null)
              // dispatch(listOrders(1))

            }}
          >
            All
            </Dropdown.Item>



          <Dropdown.Item
            onClick={() => {




              setCurrentOrderStatus(0)


              // dispatch(listOrderByStatus(0, 1))

            }}
          >
            pending
</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCurrentOrderStatus(1)

              // dispatch(listOrderByStatus(1, 1))


            }}
          >
            confirmed
</Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setCurrentOrderStatus(3)


              // dispatch(listOrderByStatus(3, 1))


            }}
          >
            Rejected
</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCurrentOrderStatus(4)

              // dispatch(listOrderByStatus(4, 1))


            }}
          >
            delivered
</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCurrentOrderStatus(5)

              // dispatch(listOrderByStatus(5, 1))


            }}
          >
            cancel
</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    );
  };



  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-4 mb-2 ${gutter ? "pagination-gutter" : ""} ${variant && `pagination-${variant}`
        } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      {items}
    </Pagination>
  );

  return (
    <>
      {loading || loadingStatusUpdate ? (
        <Loader />
      ) : error || errorStatusUpdate ? (
        <Message variant="danger">{error}</Message>
      ) : (
            <div>
              {/* <Nav>{pag("", true, "danger", true, false)}</Nav> */}
              <Pagination
                className={`mt-4 mb-2 pagination-gutter
        pagination-danger
      `}
              >
                {items}
              </Pagination>
              <Card className='my-3'>
                <Card.Header>
                  <Card.Title></Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table size="sm" className="table-responsive-md mb-0">
                    <thead>
                      <tr>
                        <th> Order Id</th>
                        <th> User</th>
                        <th> Total Price</th>
                        <th> Date</th>
                        <th>
                          <div class="login-logo-container">
                            Status
                          {buildDropDown()}

                          </div>









                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((item, index) => (
                        <tr key={index}>
                          <td
                            onClick={() => {
                              history.push(`/orders/orderdetails/${item.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.id}
                          </td>
                          <td
                            onClick={() => {
                              history.push(`/orders/orderdetails/${item.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.user.name}
                          </td>
                          <td
                            onClick={() => {
                              history.push(`/orders/orderdetails/${item.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.total_amount}
                          </td>
                          <td
                            onClick={() => {
                              history.push(`/orders/orderdetails/${item.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.created_at}
                          </td>
                          <td
                            onClick={() => {
                              history.push(`/orders/orderdetails/${item.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.payment_status === 0 ? (
                              <Badge variant="warning">pending</Badge>
                            ) : (
                                ""
                              )}

                            {item.payment_status === 1 ? (
                              <Badge variant="success">confirmed</Badge>
                            ) : (
                                ""
                              )}

                            {item.payment_status === 3 ? (
                              <Badge variant="danger">rejected</Badge>
                            ) : (
                                ""
                              )}

                            {item.payment_status === 4 ? (
                              <Badge variant="secondary">delivered</Badge>
                            ) : (
                                ""
                              )}

                            {item.payment_status === 5 ? (
                              <Badge variant="danger">cancelled</Badge>
                            ) : (
                                ""
                              )}
                          </td>

                          {

                            (user.user.typeofuser === "A" || user.user.typeofuser === "U") ? "" :


                              <td>
                                <Dropdown
                                  style={{
                                    position: "relative",
                                    marginBottom: "10px",
                                  }}
                                  onClick={() => {
                                    checkPermission(history, "orders.update");
                                  }}
                                >
                                  <div>
                                    <Dropdown.Toggle
                                      variant=""
                                      className="table-dropdown icon-false"
                                      style={{
                                        position: "relative",

                                        marginBottom: "10px",
                                      }}
                                    >
                                      <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </Dropdown.Toggle>
                                  </div>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        let formdata = new FormData();
                                        formdata.set("order_id", item.id);
                                        formdata.set("status", 0);
                                        dispatch(updateOrderStatus(dispatch, formdata));
                                      }}
                                    >
                                      pending
                            </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        let formdata = new FormData();
                                        formdata.set("order_id", item.id);
                                        formdata.set("status", 1);
                                        dispatch(updateOrderStatus(dispatch, formdata));
                                      }}
                                    >
                                      confirmed
                            </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        let formdata = new FormData();
                                        formdata.set("order_id", item.id);
                                        formdata.set("status", 3);
                                        dispatch(updateOrderStatus(dispatch, formdata));
                                      }}
                                    >
                                      Rejected
                            </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        let formdata = new FormData();
                                        formdata.set("order_id", item.id);
                                        formdata.set("status", 4);
                                        dispatch(updateOrderStatus(dispatch, formdata));
                                      }}
                                    >
                                      delivered
                            </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item
                                      onClick={() => {
                                        let formdata = new FormData();
                                        formdata.set("order_id", item.id);
                                        formdata.set("status", 5);
                                        dispatch(updateOrderStatus(dispatch, formdata));
                                      }}
                                      className="text-danger"
                                    >
                                      cancel
                            </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          )}
    </>
  );
};

export default OrdersScreen;
