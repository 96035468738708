import Shop from "./shopmodel";
import Variation from "./variationmodel";

class Product {
    id: string;
    name_ar: string;
    name_en: string;
    description_ar: string;
    description_en: string;
    bioEn:string|null;
    bioAr:string|null;
    isActive: boolean;
    categoryId: string;
    shopId: string;
    isBestSeller: boolean;
    isSpecial: boolean;
    coverImage: string;
    shop: Shop;
    variations: Variation[];

    hasColorVariation: boolean;
    hasSizeVariation: boolean;


    constructor(
        id: string,
        name_ar: string,
        name_en: string,
        description_ar: string,
        description_en: string,
        isActive: boolean,
        categoryId: string,
        shopId: string,
        isBestSeller: boolean,
        isSpecial: boolean,
        coverImage: string,
        shop: Shop,
        variations: Variation[]


    ) {
        this.id = id;
        this.name_ar = name_ar;
        this.name_en = name_en;
        this.description_ar = description_ar;
        this.description_en = description_en;

        this.isActive = isActive;
        this.categoryId = categoryId;
        this.shopId = shopId;
        this.isBestSeller = isBestSeller;
        this.isSpecial = isSpecial;
        this.coverImage = coverImage;
        this.shop = shop;
        this.variations = variations;

        this.hasColorVariation = false;
        this.hasSizeVariation = false;

        this.bioEn = null;
        this.bioAr = null;
    }


    static fromJson(json: any): Product {
        console.log("producvt from json", json);


        let product =  new Product(
            json.id.toString(),
            json.name_ar,
            json.name_en,
            json.description_ar,
            json.description_en,
            json.isactive,
            json.category_id.toString(),
            json.shop_id.toString(),
            json.bestseller,
            json.special,
            json.coverimage,
            Shop.fromJson(json.shop),
            (json.variations as []).map((e) => Variation.fromJson(e))
        );

        const firstVariantColorValue = product.variations[0].colorvalue;
        const firstVariantSizeValue = product.variations[0].sizevalue;
        const bioEn = product.variations[0].bioEn;
        const bioAr = product.variations[0].bioAr;


        product.hasColorVariation = firstVariantColorValue !=null && firstVariantColorValue != "null";
        product.hasSizeVariation = firstVariantSizeValue !=null && firstVariantSizeValue != "null";
        product.bioEn = bioEn;
        product.bioAr = bioAr;

        return product;
    }
}

export default Product;


