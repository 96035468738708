export const COUPONS_REQUEST = "COUPONS_REQUEST";
export const COUPONS_SUCCESS = "COUPONS_SUCCESS";
export const COUPONS_FAIL = "COUPONS_FAIL";

export const COUPON_DETAILS_REQUEST = "COUPON_DETAILS_REQUEST";
export const COUPON_DETAILS_SUCCESS = "COUPON_DETAILS_SUCCESS";
export const COUPON_DETAILS_FAIL = "COUPON_DETAILS_FAIL";

export const COUPON_CREATE_REQUEST = "COUPON_CREATE_REQUEST";
export const COUPON_CREATE_SUCCESS = "COUPON_CREATE_SUCCESS";
export const COUPON_CREATE_FAIL = "COUPON_CREATE_FAIL";

export const COUPON_DELETE_REQUEST = "COUPON_DELETE_REQUEST";
export const COUPON_DELETE_SUCCESS = "COUPON_DELETE_SUCCESS";
export const COUPON_DELETE_FAIL = "COUPON_DELETE_FAIL";

