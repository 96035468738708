class Category {
    id: string;
    name_en: string;
    name_ar: string;
    active: boolean;
    imageUrl: string;


    constructor(
        id: string,
        name_en: string,
        name_ar: string,
        active: boolean,
        imageUrl: string,) {

        this.id = id;
        this.name_en = name_en;
        this.name_ar = name_ar;
        this.active = active;
        this.imageUrl = imageUrl;

    };


    static fromJson(json: any): Category {

        return new Category(
            json.id.toString(),
            json.name_en,
            json.name_ar,
            json.active,
            json.fullimageurl,

        );
    }
}

export default Category;