import { Col, Modal, Row, Card } from "react-bootstrap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import TextField from "../components/TextField";
import { SketchPicker } from "react-color";
import axios from "axios";
import Variation from "../../models/variationmodel";
import Shop from "../../models/shopmodel";
import Product from "../../models/productmodel";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FormikProps, FormikValues } from "formik";
import Asset from "../../models/assetmodel";

type passNothing = () => void;
type passVariation = (variation: Variation) => void;
type twoBool = (hasColor: boolean, hasSize: boolean) => void;

interface Props {
  disableVariationChoice: boolean;
  hasColorVariant: boolean;
  hasSizeVariant: boolean;
  variation: Variation;
  onPressedSave: passVariation;
  onPressedCancel: passNothing;
}

const AddVariationModal = ({
  disableVariationChoice,
  hasColorVariant,
  hasSizeVariant,
  variation,
  onPressedSave,
  onPressedCancel,
}: Props) => {
  const [colorValue, setColorValue] = useState("FAF5F5");
  const [hasColor, setHasColor] = useState(hasColorVariant);
  const [hasSize, setHasSize] = useState(hasSizeVariant);
  const [allImageList, setAllImageList] = useState<Asset[]>([]);
  const [deletedImageIndexesList, setDeletedImageIndexesList] = useState<number[]>([]);

  const [hasOffer, setHasOffer] = useState(false);

  const formikRef = useRef();

  useLayoutEffect(() => {
    if (variation != null) {
      if (variation.colorvalue != null) {
        setColorValue(variation.colorvalue);
        setHasColor(true);
      }

      if (variation.sizevalue != null ) {
        setHasSize(true);

      }

      if (variation.hasOffer) {
        setHasOffer(variation.hasOffer);
      }

      if (variation.images != null) {
        setAllImageList(variation.images);
      }

    }
  }, []);


  useEffect(() => {
    if (formikRef.current != null) {
      const seletedImageList = allImageList.filter(
        //@ts-ignore
        asset => !deletedImageIndexesList.includes(asset.id));
      //@ts-ignore
      formikRef?.current?.setFieldValue("images", seletedImageList);
    }
  }, [allImageList, deletedImageIndexesList]);

  const getValidationSchema = () =>
    Yup.object({
      price: Yup.number().required("Required").min(0),
      stocks: Yup.number().required("Required").min(0),
      offerprice: hasOffer
        ? Yup.number()
          .required("Required")
          .min(0)
          .when(["price"], (price, schema) => {
            return schema.max(
              price,
              "offerprice cannot exceed regular price"
            );
          })
        : Yup.number().notRequired(),
      colorname: hasColor
        ? Yup.string().required("Required")
        : Yup.string().notRequired(),
      size: hasSize
        ? Yup.string().required("Required")
        : Yup.string().notRequired(),

      images: Yup.array().min(1, "At least one image is required"),
    });

  // useEffect(() => {
  //   if (formikRef.current != null) {
  //     const seletedImageList = allImageList.filter(
  //       (e) => !deletedImageIndexesList.includes(e)
  //     );
  //     (formikRef.current as FormikProps<any>).setFieldValue(
  //       "images",
  //       seletedImageList
  //     );
  //   }
  // }, [allImageList, deletedImageIndexesList]);

  function onDeleteImage(asset: Asset) {
    const isNetworkAsset = !asset.imagePath.toString().includes("blob");

    if (isNetworkAsset) {
      let temparr = [...deletedImageIndexesList];
      temparr.push(asset.id as number);
      setDeletedImageIndexesList(temparr);
    } else {
      let arr = allImageList.filter((e) => {
        return e.imagePath !== asset.imagePath;
      });
      setAllImageList(arr);
    }
  };


  const onPressedUploadImage = (e: any, formik: FormikProps<any>) => {
    console.log("e.target.files", e.target.files);
    if (e.target.files) {
      var isImageSizeValid = true;
      Array.from(e.target.files).forEach((file) => {
        if ((file as File).size / (1024 * 1024) > 2) {
          alert("Please upload a maximum of 2MB ");
          isImageSizeValid = false;
        }
      });

      if (!isImageSizeValid) {
        return;
      }


      const assetArray = Array.from(e.target.files).map(file => {
        const imageUrl = URL.createObjectURL(file as File);
        return new Asset(null, false, imageUrl, file as File);
      })

      // Array.from(e.target.files).map((file) => URL.revokeObjectURL(file as File));

      let tempArray = [...allImageList];
      tempArray.push(...assetArray);
      setAllImageList(tempArray);
    }

    e.target.value = null;
  };

  const renderHasColorCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={hasColor}
          disabled={!hasSize}
          onChange={(e) => {
            setHasColor(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Color Variant
        </label>
      </div>
    );
  };

  const renderHasSizeCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={hasSize}
          disabled={!hasColor}
          onChange={(e) => {
            setHasSize(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Size Variant
        </label>
      </div>
    );
  };

  const renderPriceField = () => {
    return <TextField label="Price" name="price" type="number" />;
  };

  const renderOfferPriceField = () => {
    return <TextField label="Offer Price" name="offerprice" type="number" />;
  };

  const renderStockField = () => {
    return <TextField label="Stock" name="stocks" type="number" />;
  };

  const renderColorField = () => {
    return <TextField label="Color Name" name="colorname" type="text" />;
  };

  const renderSizeField = () => {
    return <TextField label="Size" name="size" type="text" />;
  };

  const renderHasOfferCheckBox = () => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={hasOffer}
          onChange={(e) => {
            setHasOffer(e.target.checked);
          }}
        />

        <label className="form-check-label" htmlFor="Active">
          Has Offer
        </label>
      </div>
    );
  };

  const renderOkButton = () => {
    return (
      <button
        className={"btn btn-primary"}
        type="submit"
      // onClick={()=>{

      // }}
      //     onClick={() => {
      //       axios
      //         .get("https://www.api.kmz.ae/api/v2/admin/product")
      //         .then((responses) => {
      //           console.log("response.data", response.data);

      //           let variation = Variation.fromJson(response.data.variations[0]);
      //           console.log("variationsss", variation);

      //           let shop = Shop.fromJson(response.data.shop);
      //           console.log("shop", shop);

      //           let product = Product.fromJson(response.data);
      //           console.log("product", product);
      //         });
      //       // onPressedSave(hasColor, hasSize);
      //     }
      // }
      >
        Ok
      </button>
    );
  };

  const renderProductImageSection = (formik: FormikProps<any>) => {
    const filteredImageList = allImageList.filter((asset) => !deletedImageIndexesList.includes(asset.id));

    return (
      <div>
        <Row>
          <div style={{ width: "120px" }}>
            <label
              style={{ cursor: "pointer" }}
              className="text-nowrap border shadow py-3 px-4 bg-white text-success add-photo rounded w-100"
              htmlFor="file"
            >
              <i className="bx bx-image-add my-5 mx-4"> </i>
            </label>
            <input
              name="images"
              type="file"
              id="file"
              multiple={true}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => onPressedUploadImage(e, formik)}
            />
          </div>

          {filteredImageList.map((asset, index) => {
            return (
              <Col md={3} sm={4} xs={5}>
                <div className="result rounded">
                  <Card
                    className="my-2 p-1 rounded"
                    style={{ height: "180px", objectFit: "contain" }}
                  >
                    <Card.Img
                      style={{ height: "170px", objectFit: "contain" }}
                      src={asset.imagePath}
                      variant="top"
                      key={asset.imagePath}
                    />
                    <button
                      onClick={(e) => onDeleteImage(asset)}
                      type="button"
                      className="btn btn-white text-danger rounded fs-3"
                      style={{ position: "absolute" }}
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </Card>
                </div>
              </Col>
            );
          })}
        </Row>
        <ErrorMessage
          component="div"
          className="error text-danger"
          name={"images"}
        />
      </div>
    );
  };

  const renderColorPicker = () => {
    return (
      <SketchPicker
        color={colorValue}
        onChange={(updatedColor: any) => {
          setColorValue(updatedColor.hex);
          //   formik.setFieldValue("color_value", updatedColor.hex);
        }}
        width="300px"
        height="50px"
      />
    );
  };

  const renderColorPreviewField = () => {
    return (
      <div>
        <label></label>
        <input
          className={`form-control shadow-none rounded`}
          style={{ backgroundColor: `${colorValue}` }}
          disabled={true}
        />
      </div>
    );
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        onPressedCancel();
      }}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Variation Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={
            variation == null
              ? {
                price: "",
                stocks: "",
                offerprice: "",
                colorname: "",
                size: "",
                images: [],
              }
              : {
                price: variation.price,
                stocks: variation.stocks,
                offerprice: variation.offerprice,
                colorname: variation.colorName||"",
                size: variation.sizevalue || "",
                images: variation.images,
              }
          }
          validationSchema={getValidationSchema()}
          onSubmit={(values) => {

            let newVariation = new Variation(
              variation != null ? variation.id : null,
              "",
              Number(values.price),
              Number(values.offerprice),
              hasOffer,
              Number(values.stocks),
              hasSize ? values.size : null,
              hasColor ? colorValue : null,
              "",
              values.images as string[],
              "",
              "",
              values.colorname
            );

            newVariation.deletedImageIndexes = deletedImageIndexesList;

            console.log("before", newVariation);

            onPressedSave(newVariation);
          }}
        >
          {(formik) => {
            console.log("Variation modal formik", formik);
            return (
              <Form>
                {!disableVariationChoice && (
                  <Row>
                    <Col>{renderHasColorCheckBox()}</Col>

                    <Col>{renderHasSizeCheckBox()}</Col>
                  </Row>
                )}

                <div className="my-4" />

                {renderProductImageSection(formik)}
                <Row>
                  <Col>{renderPriceField()}</Col>
                  <Col>{renderStockField()}</Col>
                </Row>

                {hasOffer && renderOfferPriceField()}
                {renderHasOfferCheckBox()}

                <div className="my-4" />

                {hasSize && renderSizeField()}

                {hasColor && (
                  <Row>
                    <Col sm={12} md={5} lg={5}>
                      {renderColorPicker()}
                    </Col>

                    <span className="mx-4 " />

                    <Col sm={12} md={12} lg={5}>
                      <div className="my-4 " />

                      <Row>
                        <Col xs={10} md={9}>
                          {renderColorField()}
                        </Col>

                        <Col xs={2} md={3}>
                          {renderColorPreviewField()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <div className="my-4 " />

                <Row className="float-right">{renderOkButton()}</Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddVariationModal;
