import Category from "../models/categorymodel";
import { BASE_URL } from "../constants/Globals";
import axios, { AxiosRequestConfig } from "axios";


class CategoriesApi {

    static async getAllCategories(): Promise<Category[]> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
                Accept: 'application/json'
            },
        };

        let categoryList: Category[] = []; 
        try {
            const response = await axios.get(
                `${BASE_URL}api/v2/admin/category`,
                config
            );

            console.log("resposnse", response)
            console.log("resposnse", response)
            console.log("resposnse", response)




            for (let i = 0;  i < response.data.length; i++) {
                console.log(i, response.data[i])
                let category: Category = Category.fromJson(response.data[i]);

                categoryList.push(category);
            }
        } catch (e) {
            console.log("Error when getting all shopsList: ", e);
            throw e.toString();
        }

        return categoryList;
    }






}


export default CategoriesApi;