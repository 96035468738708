import React, { useEffect, useLayoutEffect, useState } from "react";
import { Badge, Card, Col, Image, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listOrderDetailsById } from "../../actions/orderActions";
import { listProductDetails } from "../../actions/productActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Button } from "react-bootstrap";


const OrderDetailsScreen = ({ match, history, setHasVariant }) => {
  const orderDetailsList = useSelector((state) => state.orderDetailsList);
  const { loading, error, ordersDetails } = orderDetailsList;

  const orderId = match.params.id;


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listOrderDetailsById(orderId));
  }, [dispatch, orderId]);

  useLayoutEffect(() => {
    dispatch(listOrderDetailsById(orderId));
    console.log(ordersDetails);
  }, [dispatch, orderId]);

  let hasCoordinates = (ordersDetails?.la !== null && ordersDetails?.lng !== null);



  const renderColorSection = (color) => {
    return (
      <Row >
        <h5>Color:</h5>
        <div className=" mx-1" />
        <div
          style={{
            backgroundColor: `${color}`, height: "25px",
            width: '25px',
            borderRadius: "50%",
            display: "inline-block"
          }}
        />
      </Row>
    );
  };

  const renderSizeSection = (size) => {
    return (
      <Row><h5>Size: {size}</h5></Row>

    );
  };



  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>

          {ordersDetails && ordersDetails.user && (

            <Row>
              <Col md={8}>
                <Card border="light" className="bg-white shadow-sm">
                  <Card.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <h2>
                          {" "}
                          {ordersDetails &&
                            ordersDetails.payment_status === 0 ? (
                            <Badge variant="warning">pending</Badge>
                          ) : (
                            ""
                          )}
                          {ordersDetails &&
                            ordersDetails.payment_status === 1 ? (
                            <Badge variant="success">confirmed</Badge>
                          ) : (
                            ""
                          )}
                          {ordersDetails.payment_status === 2 ? (
                            <Badge variant="primary">shipping</Badge>
                          ) : (
                            ""
                          )}
                          {ordersDetails.payment_status === 3 ? (
                            <Badge variant="danger">rejected</Badge>
                          ) : (
                            ""
                          )}
                          {ordersDetails.payment_status === 4 ? (
                            <Badge variant="secondary">delivered</Badge>
                          ) : (
                            ""
                          )}
                          {ordersDetails.payment_status === 5 ? (
                            <Badge variant="danger">cancelled</Badge>
                          ) : (
                            ""
                          )}
                        </h2>
                        <p>
                          <strong>Name: </strong>{" "}
                          {ordersDetails &&
                            ordersDetails.user &&
                            ordersDetails.user.name}
                        </p>
                        <p>
                          <strong>Email: </strong>
                          {ordersDetails.user.email}
                        </p>


                        <div>

                          <strong>Address: </strong>
                          <span>{ordersDetails.address}</span>&nbsp;&nbsp;&nbsp;  <i
                            className="fa fa-map"
                            style={{
                              cursor: hasCoordinates ? "pointer" : "default",
                              color: hasCoordinates ? "#964f83" : "#afb3b0",
                            }}
                            onClick={(e) => {
                              // console.log(ordersDetails)
                              if (!hasCoordinates) return;

                              window.open(`https://www.google.com/maps/search/?api=1&query=${ordersDetails.lat},${ordersDetails.lng}`, "_blank")
                            }}
                          />
                        </div>
                        <p>
                          <strong>Phone: </strong>
                          {ordersDetails.phone}
                        </p>
                        <p>
                          <strong>Whatsapp: </strong>
                          {ordersDetails.haswhatsapp === true ? "Yes" : "No"}
                        </p>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <h2>Order Items</h2>

                        <ListGroup variant="flush">
                          {ordersDetails.products &&
                            ordersDetails.products.map((item, index) => {

                              let hasColorVariant = (item.variation?.color_value) != null && (item.variation?.color_value)!='null';
                              let hasSizeVariant = (item.variation?.size_value) != null && (item.variation?.size_value)!='null';
                              let isVariationImagesNotEmpty =(item.variation?.images != null) && (item.variation?.images?.length > 0);

                              return <Link
                                to={`/ecom/product-edit/${item.product_info && item.product_info.id
                                  }`}
                              >
                                <ListGroup.Item
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      item.product_info &&
                                      item.product_info.variations.length ===
                                      1 &&
                                      item.product_info &&
                                      item.product_info.variations[0]
                                        .color_name === null &&
                                      item.product_info &&
                                      item.product_info.variations[0]
                                        .size_value === null
                                    ) {
                                      // setHasVariant({ checked: false });
                                    }
                                    // } else {
                                    //   setHasVariant({ checked: true });
                                    // }
                                  }}
                                >
                                  <Row>
                                    <Col md={2} >
                                      <Image
                                        src={
                                          isVariationImagesNotEmpty?  item.variation.images[0]:""
                                        }
                                        alt={''}
                                        fluid
                                        rounded
                                      />
                                    </Col>
                                    <Col>

                                      <Row>
                                        {item.name_en}
                                      </Row>

                                      {hasSizeVariant && renderSizeSection(item.variation?.size_value)}
                                      {hasColorVariant && renderColorSection(item.variation?.color_value)}





                                    </Col>
                                    <Col md={4}>
                                      {item.quantity} x AED {item.product_price} =
                                      AED {item.quantity * item.product_price}
                                    </Col>
                                  </Row>

                                </ListGroup.Item>
                              </Link>
                            })}
                        </ListGroup>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col>Items</Col>
                        <Col>{ordersDetails.products.length}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Shipping</Col>
                        <Col>AED {ordersDetails.delivery_charge}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Tax</Col>
                        <Col>AED {ordersDetails.tax}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Total</Col>
                        <Col>AED {ordersDetails.total_amount}</Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default OrderDetailsScreen;
