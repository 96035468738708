export const SHOP_REQUEST = "SHOP_REQUEST";
export const SHOP_SUCCESS = "SHOP_SUCCESS";
export const SHOP_FAIL = "SHOP_FAIL";

export const SHOP_DETAILS_REQUEST = "SHOP_DETAILS_REQUEST";
export const SHOP_DETAILS_SUCCESS = "SHOP_DETAILS_SUCCESS";
export const SHOP_DETAILS_FAIL = "SHOP_DETAILS_FAIL";

export const SHOP_DELETE_REQUEST = "SHOP_DELETE_REQUEST";
export const SHOP_DELETE_SUCCESS = "SHOP_DELETE_SUCCESS";
export const SHOP_DELETE_FAIL = "SHOP_DELETE_FAIL";

export const SHOP_CREATE_REQUEST = "SHOP_CREATE_REQUEST";
export const SHOP_CREATE_SUCCESS = "SHOP_CREATE_SUCCESS";
export const SHOP_CREATE_FAIL = "SHOP_CREATE_FAIL";

export const ALL_SHOP_REQUEST = "ALL_SHOP_REQUEST";
export const ALL_SHOP_SUCCESS = "ALL_SHOP_SUCCESS";
export const ALL_SHOP_FAIL = "ALL_SHOP_FAIL";

