import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Card, NavItem, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCategory, getCategory } from "../../actions/categoryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import debounce from "lodash.debounce";
import checkPermission from "./checkpermission";
import { deleteSlider, getSlider } from "../../actions/sliderActions";



const SliderScreen = ({ history }) => {

  const [inputValue, setInputValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  const sliderList = useSelector((state) => state.sliderList);
  const { loading, slidersError, sliders } = sliderList;

  const sliderDelete = useSelector((state) => state.sliderDelete);
  const { loading: loadingDelete, error: errorDelete } = sliderDelete;


  // console.log("loading",loading);


  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(loading)
  }, [loading]);


  useEffect(() => {
    dispatch(getSlider());
  }, [dispatch,]);




  const deleteSliderHandler = async (id) => {
    let formdata = new FormData();
    formdata.set("delete", id);
    if (window.confirm("Are you sure")) {
      dispatch(deleteSlider(id));
    }
  };

  // console.log("ISLOADING::::", isLoading);


  return (
    <>
      {isLoading || loadingDelete ? (
        <Loader />
      ) : slidersError || errorDelete ? (
        <Message variant="danger">{slidersError || errorDelete}</Message>
      ) : (
            <div>
              {/* {console.log(
                "test", sliderList
              )} */}
              <div className="d-flex justify-content-between my-4">
                <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'flex-start', gap: '10px' }}>
                  <Link to="/addnewslider">
                    <Button variant="secondary mb-2">Add new banner</Button>
                  </Link>
                  {/* <Link to="/addnewslider">
                    <Button variant="secondary mb-2">Add Bottom Slider</Button>
                  </Link> */}
                </div>
              </div>
              <h3>Top Banners</h3>



              <Card>
                <Card.Body>
                  <Table responsive className="header-border ">
                    <thead>
                      <tr >
                        <th> Id</th>
                        <th> Images</th>
                        <th> Aspect Ratio</th>
                        <th> Location</th>

                      </tr>
                    </thead>
                    <tbody>

            

                      {sliders?.top.length > 0 && (


                        sliders?.top &&
                        sliders?.top.map((item, index) => {
                          return (

                            <tr >
                              <td
                                style={{
                                  cursor: "pointer",
                                  height: '100px'
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);
                                }}
                              >
                                {item.id}
                              </td>
                              <span className="mx-4 my-4">
                                <Card.Img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "contain",
                                  }}
                                  src={item.fullurl}
                                  variant="top"
                                />
                              </span>

                              <td
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);

                                  // history.push(`/slider/edit/${sliders?.bottom[0].id}`);
                                }}
                              >
                                {item.aspectratio}
                              </td>

                              <td
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);

                                  // history.push(`/slider/edit/${sliders?.bottom[0].id}`);
                                }}
                              >
                                {"Top"}
                              </td>



                              <td>
                                <div className="d-flex justify-content-around">
                                  <i
                                    className="fa fa-trash"
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      checkPermission(history, "category.delete");

                                      deleteSliderHandler(item.id);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        }


                        ))}


        
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <h3>Bottom Banners</h3>



              <Card>
                <Card.Body>
                  <Table responsive className="header-border ">
                    <thead>
                      <tr >
                        <th> Id</th>
                        <th> Images</th>
                        <th> Aspect Ratio</th>
                        <th> Location</th>

                      </tr>
                    </thead>
                    <tbody>

                      {sliders?.bottom.length > 0 && (


                        sliders?.bottom &&
                        sliders?.bottom.map((item, index) => {
                          return (

                            <tr >
                              <td
                                style={{
                                  cursor: "pointer",
                                  height: '100px'
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);
                                }}
                              >
                                {item.id}
                              </td>
                              <span className="mx-4 my-4">
                                <Card.Img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "contain",
                                  }}
                                  src={item.fullurl}
                                  variant="top"
                                />
                              </span>

                              <td
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);

                                  // history.push(`/slider/edit/${sliders?.bottom[0].id}`);
                                }}
                              >
                                {item.aspectratio}
                              </td>

                              <td
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history.push(`/slider/edit/${item.id}`);

                                  // history.push(`/slider/edit/${sliders?.bottom[0].id}`);
                                }}
                              >
                                {"Bottom"}
                              </td>



                              <td>
                                <div className="d-flex justify-content-around">
                                  <i
                                    className="fa fa-trash"
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      checkPermission(history, "category.delete");

                                      deleteSliderHandler(item.id);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        }


                        ))}


                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </div>
          )}
    </>
  );
};

export default SliderScreen;
