export const SLIDER_REQUEST = 'SLIDER_REQUEST';
export const SLIDER_SUCCESS = 'SLIDER_SUCCESS';
export const SLIDER_FAIL = 'SLIDER_FAIL';

export const SLIDER_DETAILS_REQUEST = 'SLIDER_DETAILS_REQUEST';
export const SLIDER_DETAILS_SUCCESS = 'SLIDER_DETAILS_SUCCESS';
export const SLIDER_DETAILS_FAIL = 'SLIDER_DETAILS_FAIL';

export const SLIDER_CREATE_REQUEST = 'SLIDER_CREATE_REQUEST';
export const SLIDER_CREATE_SUCCESS = 'SLIDER_CREATE_SUCCESS';
export const SLIDER_CREATE_FAIL = 'SLIDER_CREATE_FAIL';

export const SLIDER_DELETE_REQUEST = 'SLIDER_DELETE_REQUEST';
export const SLIDER_DELETE_SUCCESS = 'SLIDER_DELETE_SUCCESS';
export const SLIDER_DELETE_FAIL = 'SLIDER_DELETE_FAIL';
