import axios from 'axios';
import {
  APPSETTINGS_REQUEST,
  APPSETTINGS_SUCCESS,
  APPSETTINGS_FAIL,
  APPSETTINGS_UPDATE_REQUEST,
  APPSETTINGS_UPDATE_SUCCESS,
  APPSETTINGS_UPDATE_FAIL
} from '../constants/appSettingsContants';
import { BASE_URL } from '../constants/Globals';

export const getAppSettings = (keyword) => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));


  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.success.token}`,
    },
  };

  console.log(userInfo.success.token);

  try {
    dispatch({ type: APPSETTINGS_REQUEST });

    let resdata;

    if (keyword === '' || keyword === undefined || keyword === null) {
      const { data } = await axios.get(`${BASE_URL}api/v2/admin/appsettings`, config);
      resdata = data;
    } else {
      const { data } = await axios.get(
        `${BASE_URL}api/v2/admin/appsettings`,
        config
      );
      resdata = data;
    }


    var saudiDeliveryFee;
    var omanDeliveryFee;
    var abuDhabiDeliveryFee;
    var dubaiDeliveryFee;
    var sharjahDeliveryFee;
    var rasAlKhaimaiDeliveryFee;
    var ummAlQuwainDeliveryFee;
    var ajmanDeliveryFee;
    var fujairahDeliveryFee;
    var westernRegionDeliveryFee;
    var saudiDeliveryFee;
    var alAinDeliveryFee;
    var categoryAllImg;



    for (let i = 0; i < resdata.settings.length; i++) {
      var setting = resdata.settings[i];
      switch (setting.key) {
        case "delivery_fee_ksa":
          saudiDeliveryFee = setting['value']

          break;

        case "delivery_fee_oman":
          omanDeliveryFee = setting['value']

          break;

        case "delivery_fee_abudhabi":
          abuDhabiDeliveryFee = setting['value']
          break;

        case "delivery_fee_dubai":
          dubaiDeliveryFee = setting['value']
          break;

        case "delivery_fee_sharjah":
          sharjahDeliveryFee = setting['value']
          break;

        case "delivery_fee_ajman":
          ajmanDeliveryFee = setting['value']
          break;

        case "delivery_fee_rak":
          rasAlKhaimaiDeliveryFee = setting['value']
          break;

        case "delivery_fee_umm":
          ummAlQuwainDeliveryFee = setting['value']
          break;

        case "delivery_fee_alain":
          alAinDeliveryFee = setting['value']
          break;

        case "delivery_fee_fujairah":
          fujairahDeliveryFee = setting['value']
          break;
        case "delivery_fee_western":
          westernRegionDeliveryFee = setting['value']
          break;

        case "category_all_img":
          categoryAllImg = setting['value'];

          break;

        default:
          console.log("Unknown App Setting");
      }
    }


    var temp = {

      "saudiDeliveryFee": saudiDeliveryFee,
      "omanDeliveryFee": omanDeliveryFee,
      "abuDhabiDeliveryFee": abuDhabiDeliveryFee,
      "dubaiDeliveryFee": dubaiDeliveryFee,
      "sharjahDeliveryFee": sharjahDeliveryFee,
      "rasAlKhaimaiDeliveryFee": rasAlKhaimaiDeliveryFee,
      "ummAlQuwainDeliveryFee": ummAlQuwainDeliveryFee,
      "ajmanDeliveryFee": ajmanDeliveryFee,
      "fujairahDeliveryFee": fujairahDeliveryFee,
      "alAinDeliveryFee":alAinDeliveryFee,
      "westernRegionDeliveryFee":westernRegionDeliveryFee,
      "saudiDeliveryFee": saudiDeliveryFee,
      "categoryAllImg":categoryAllImg,

    }





    const data = temp



    dispatch({
      type: APPSETTINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPSETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const updateAppSettings = (dispatch, formdata) => async () => {
  try {

    dispatch({
      type: APPSETTINGS_REQUEST,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}api/v2/admin/appsettings`,
      formdata,
      config
    );

    var saudiDeliveryFee;
    var omanDeliveryFee;
    var abuDhabiDeliveryFee;
    var dubaiDeliveryFee;
    var sharjahDeliveryFee;
    var rasAlKhaimaiDeliveryFee;
    var ummAlQuwainDeliveryFee;
    var ajmanDeliveryFee;
    var fujairahDeliveryFee;
    var westernRegionDeliveryFee;
    var saudiDeliveryFee;
    var alAinDeliveryFee;
    var categoryAllImg;




    for (let i = 0; i < data.settings.length; i++) {
      var setting = data.settings[i];
      switch (setting.key) {
        case "delivery_fee_ksa":
          saudiDeliveryFee = setting['value']

          break;

        case "delivery_fee_oman":
          omanDeliveryFee = setting['value']

          break;

        case "delivery_fee_abudhabi":
          abuDhabiDeliveryFee = setting['value']
          break;

        case "delivery_fee_dubai":
          dubaiDeliveryFee = setting['value']
          break;

        case "delivery_fee_sharjah":
          sharjahDeliveryFee = setting['value']
          break;

        case "delivery_fee_ajman":
          ajmanDeliveryFee = setting['value']
          break;

        case "delivery_fee_rak":
          rasAlKhaimaiDeliveryFee = setting['value']
          break;

        case "delivery_fee_umm":
          ummAlQuwainDeliveryFee = setting['value']
          break;

        case "delivery_fee_alain":
          alAinDeliveryFee = setting['value']
          break;

        case "delivery_fee_fujairah":
          fujairahDeliveryFee = setting['value']
          break;
        case "delivery_fee_western":
          westernRegionDeliveryFee = setting['value']
          break;

        case "category_all_img":
          categoryAllImg = setting['value'];

          break;

        default:
          console.log("Unknown App Setting");
      }
    }


    var temp = {

      "saudiDeliveryFee": saudiDeliveryFee,
      "omanDeliveryFee": omanDeliveryFee,
      "abuDhabiDeliveryFee": abuDhabiDeliveryFee,
      "dubaiDeliveryFee": dubaiDeliveryFee,
      "sharjahDeliveryFee": sharjahDeliveryFee,
      "rasAlKhaimaiDeliveryFee": rasAlKhaimaiDeliveryFee,
      "ummAlQuwainDeliveryFee": ummAlQuwainDeliveryFee,
      "ajmanDeliveryFee": ajmanDeliveryFee,
      "fujairahDeliveryFee": fujairahDeliveryFee,
      "alAinDeliveryFee":alAinDeliveryFee,
      "westernRegionDeliveryFee":westernRegionDeliveryFee,
      "saudiDeliveryFee": saudiDeliveryFee,
      "categoryAllImg":categoryAllImg,

    }










    dispatch({
      type: APPSETTINGS_SUCCESS,
      payload: temp,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.error
        ? error.response.data.error
        : error.message;
    /*if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }*/
    dispatch({
      type: APPSETTINGS_FAIL,
      payload: message,
    });
  }
};

