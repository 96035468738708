import Asset from "./assetmodel";

class Variation {
    id: string|null;
    product_id: string;
    price: number;
    offerprice: number;
    hasOffer: boolean;
    stocks: number;
    sizevalue: string;
    colorvalue: string;
    colorName: string;
    sku: string;
    images: Asset[];
    deletedImageIndexes:number[];
    bioEn:string;
    bioAr:string;

    constructor(
        id: string,
        product_id: string,
        price: number,
        offer_price: number,
        hasOffer: boolean,
        stocks: number,
        sizevalue: string,
        colorvalue: string,
        sku: string,
        images: Asset[],
        bioEn:string,
        bioAr:string,
        colorName:string,

        ) {

        this.id = id;
        this.product_id = product_id;
        this.price = price;
        this.offerprice = offer_price;
        this.hasOffer = hasOffer;
        this.stocks = stocks;
        this.sizevalue = sizevalue;
        this.colorvalue = colorvalue
        this.sku = sku;
        this.images = images;
        this.deletedImageIndexes = [];
        this.bioEn = bioEn;
        this.bioAr= bioAr;
        this.colorName = colorName;
    };


    static fromJson(json: any): Variation {

        console.log('json', json);

        return new Variation(
            json.id.toString(),
            json.product_id.toString(),
            Number(json.price),
            Number(json.offerprice),
            json.hasoffer,
            parseInt(json.stocks),
            ((json.size_value ==null) || (json.size_value =="null"))? null:json.size_value,
            ((json.color_value == null) || (json.color_value =="null"))?null:json.color_value,
            ((json.color_name == null) || (json.color_value =="null"))?null:json.color_name,
            json.images.map((e:string, index:number) => new Asset(index, true, e,null)),
            (json.bio_en == "null" || json.bio_en == null)? null:json.bio_en,
            (json.bio_ar == "null" || json.bio_ar == null)? null:json.bio_ar,
            ((json.color_name == null) || json.color_name=="null")?null:json.color_name,

        );


    }





}


export default Variation;

