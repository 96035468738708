import axios from "axios";
import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../constants/userConstants";

import { BASE_URL } from "../constants/Globals";
import { createPermission, updatePermission } from "./permissionActions";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}/api/v2/public/login`,
      { email, password },
      config
    );

    console.log("user response", data)


    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}api/v2/public/register`,
      { name, email, password },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    // dispatch({
    //  type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })

    document.location.href = "/page-login";
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch, history) => {
  localStorage.removeItem("userInfo");
  // dispatch({ type: USER_LOGOUT });
  //dispatch({ type: USER_DETAILS_RESET })

  document.location.replace("/page-login");
};

export const listUsers = (pageNumber, keyword) => async (dispatch) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    dispatch({ type: USER_LIST_REQUEST });

    let resdata;

    if (keyword === "" || keyword === undefined || keyword === null) {
      const { data } = await axios.get(
        `${BASE_URL}api/v2/admin/users?page=${pageNumber}`,
        config
      );
      resdata = data;
    } else {
      const data = await axios.get(
        `${BASE_URL}api/v2/admin/adminsearch?search=${keyword}&type=users`,
        config
      );
      resdata = data;
    }

    const data = resdata;

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUser = (formdata) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    await axios.post(`${BASE_URL}api/v2/admin/deleteuser`, formdata, config);

    dispatch(listUsers(1));

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      ///dispatch(logout())
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const listUserDetails = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}api/v2/admin/fetchuser?id=${shopId}`,
      config
    );



    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const createUser = (dispatch, formdata, values, userId) => async () => {
  try {
    dispatch({
      type: USER_CREATE_REQUEST,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}api/v2/admin/createuser`,
      formdata,
      config
    );

    formdata.delete("user_id");
    if (userId) {
      formdata.set("user_id", userId);
    } else {
      formdata.set("user_id", data.success.id);
    }

    let submit = false;

    for (var pair of formdata.entries()) {
      if (pair[0] === "add_permission[]") {
        submit = true;
      }
      console.log(pair[0] + ", " + pair[1]);
    }

    if (submit) {
      if (userId) {
  
        dispatch(updatePermission(dispatch, formdata));
      } else {
        dispatch(createPermission(dispatch, formdata));
      }
    }

    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });

    dispatch(listUsers(1));
  } catch (error) {
    const message =
      error.response && error.response.data.error
        ? error.response.data.error
        : error.message;
    /*if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }*/
    dispatch({
      type: USER_CREATE_FAIL,
      payload: message,
    });
  }
};
