import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Products from "./Products";
import debounce from "lodash.debounce";
// import Select from "../../../Select";
import Select from 'react-select';

/// Data
import productData from "../productData";

import PageTitle from "../../../../layouts/PageTitle";

import { Button, Dropdown, Nav, Pagination } from "react-bootstrap";
import Paginate from "../../../Paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  listProductDetails,
  listProducts,
} from "../../../../../actions/productActions";
import { Link } from "react-router-dom";
import Loader from "../../../Loader";
import Message from "../../../Message";
import { Formik } from "formik";
import { set } from "date-fns";
import { getAllShops } from "../../../../../actions/shopActions";
import { debounce as debouncePromise } from "debounce-promise";
import axios from "axios";
import AsyncSelect from 'react-select/async';
import { BASE_URL } from "../../../../../constants/Globals";
import { options } from "preact";

const ProductGrid = ({ match, history }) => {
  const [keyword, setKeyword] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [all, setAll] = useState({ checked: true });
  const [deleted, setDeleted] = useState({ checked: false });

  const [active, setActive] = useState({ checked: false });

  const [selectedShop, setSelectedShop] = useState(undefined);

  let pageNumber = match.params.pageNumber || 1;
  const selectref = useRef();

  let items = [];
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading: productDetailsLoading, error: errorProductLoading } =
    productDetails;

  const allshops = useSelector((state) => state.allshops);
  const { loading: shoploading, shopError, shops } = allshops;

  const shopListDetails = useSelector((state) => state.shopListDetails);
  const { loading: load, error: err, shop } = shopListDetails;
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [shopss, setShopss] = useState([]);

  let shopid;

  // const populateShops = () => {
  //   // if (user.user.typeofuser === "A" || user.user.typeofuser === "U") {
  //   //   let objects = [];

  //   //   objects[0].data = {
  //   //     key: shop.shop_name,
  //   //     value: shop.id,
  //   //   };

  //   //   objects.unshift({ key: "choose", value: "" });
  //   //   setShopss(objects);

  //   //   return objects;
  //   // }

  //   // if (user.user.typeofuser === "S") {

  //   // let objects = [shops.length];
  //   // for (var x = 0; x < shops.length; x++) {
  //   //   // objects.push({ key: shops[x].shop_name_en, value: shops[x].id });
  //   //   objects[x] = { key: shops[x].shop_name_en, value: shops[x].id  }
  //   // }
  //   // setShopss(objects);
  //   // objects.unshift({ key: "Choose A Shop", value: "" });
  //   // console.log("new:", shopss)
  //   // return objects;
  //   //}
  // };

  // console.log("allshops", allshops)

  const paginationClicked = async (number) => {

    pageNumber = number;
    dispatch(listProducts(pageNumber, inputValue,(selectedShop!=null)?  selectedShop.id:null));
    history.push(`/ecom-product-grid/page/${number}`);
  };

  const debouncedSave = useCallback(
    debounce(
      (newValue) => dispatch(listProducts(1, newValue, selectedShop.id)),
      1000
    ),
    [dispatch]
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  useEffect(() => {
    populateShops();
  }, [dispatch, pageNumber, products]);

  useLayoutEffect(() => {
    dispatch(listProducts(pageNumber, inputValue,(selectedShop!=null)? selectedShop.id:null));
    dispatch(listProductDetails(0));
    dispatch(getAllShops());
    populateShops();
  }, [dispatch, pageNumber]);

  function calulatePaginationItems() {
    let pageItems = [];
    let previousPage = page - 1;
    let nextPage = page + 1;
    let currentPage = page;

    pageItems.push(<Pagination.Item onClick={() => paginationClicked(1)}>
      {'<<'}
    </Pagination.Item>);

    if (previousPage > 0) {
      pageItems.push(
        <Pagination.Item onClick={() => paginationClicked(previousPage)} >{'<'}</Pagination.Item>
      );
    }

    let paginationLength = 17;

    let lowerLimit = Math.max(currentPage - Math.floor(paginationLength / 2), 1);
    let upperLimit = Math.min(currentPage + Math.floor(paginationLength / 2), pages);
    let totalLength = upperLimit - lowerLimit + 1;

    while (totalLength < paginationLength && totalLength < pages) {
      let lowerRange = currentPage - lowerLimit;
      let upperRange = upperLimit - currentPage;

      if (lowerRange < upperRange) {
        upperLimit++;
      } else {
        lowerLimit--;
      }
      totalLength++;

    }

    for (let number = lowerLimit; number <= upperLimit; number++) {
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={(event) => paginationClicked(number)}
        >
          {number}
        </Pagination.Item>
      );
    }



    if (nextPage < pages) {
      pageItems.push(
        <Pagination.Item onClick={() => paginationClicked(nextPage)}> {'>'} </Pagination.Item>
      );
    }

    pageItems.push(
      <Pagination.Item onClick={() => paginationClicked(pages)} >{'>>'} </Pagination.Item>
    );

    return pageItems;
  }
  items = calulatePaginationItems();


  const searchShops = (resolve, inputValue) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.success.token}`,
      },
    };

    axios.get(
      `${BASE_URL}api/v2/admin/adminsearch?search=${inputValue}&type=shops`,
      config
    ).then(value => {
      console.log(value);
      resolve(value.data.map(shop => {
        return {
          value: shop.id,
          label: shop.shop_name_en
        };
      }))
    }).catch(e => { resolve([]); });

  }


  const shopOptions = (inputValue) => {
    return new Promise((resolve) => {
      searchShops(resolve, inputValue);
    });

  }


  const populateShops = () => {


    let objects = shops.map(shop => {
      return {
        "label": shop.shop_name_en,
        "value": shop.id
      }
    })


    objects.unshift({ label: "All shops", value: "" });

    return objects;

  };

  var debouncedSave2 = useCallback(
    debounce((input, shopname) => dispatch(listProducts(1, input, shopname)), 1000),
    []
  );




  return (
    <>
      {loading || shoploading || productDetailsLoading ? (
        <Loader />
      ) : error || errorProductLoading ? (
        <Message variant="danger">{error || errorProductLoading}</Message>
      ) : (
            <Fragment>
              {/* <div className="d-flex justify-content-between my-4"> */}
              <div className="row g-15 "  >
                <div className="col-md-5" style={{ marginBottom: 15 }}>
                  <input
                    className="form-control shadow-none rounded mx-2"
                    placeholder="Search Products"
                    onChange={(input) => {



                      // console.log("start",inputValue)
                      setInputValue(input.target.value)
                      // console.log("end",inputValue)

                      debouncedSave2(input.target.value, selectedShop ? selectedShop.value : "");
                      // dispatch(listProducts(1, inputValue, (selectedShop && selectedShop !=null)?selectedShop.value:""))
                      // debounce(dispatch(debouncedSave2),1000);
                    }}
                    value={inputValue}
                    autoFocus
                  />
                  {/* <div
                    className="d-flex my-3 justify-content-end"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(listProducts(1, inputValue, selectedvalue));
                    }}
                  >
                    <i
                      class="bx bx-search-alt-2"
                      style={{
                        fontSize: "2rem",
                        position: "absolute",
                        marginRight: "15px",
                      }}
                    ></i>
                  </div> */}
                </div>

                {user.user.typeofuser === "S" ? (

                  // <div>
                  //   <div className>
                  //     <select
                  //       name="shop_id"
                  //       className={`form-select form-control`}
                  //       onChange={(e) => {
                  //         shopid = e.target.value;
                  //         setSelectedValue(e.target.value);
                  //         dispatch(listProducts(1, inputValue, e.target.value));
                  //       }}
                  //       ref={selectref}
                  //       value={selectedvalue}
                  //     >
                  //       <option value =""> Choose A Shop </option>
                  //       {shops.map((option) => {
                  //         return (
                  //           <option key={option.id} value={option.id}>
                  //             {option.shop_name}
                  //           </option>
                  //         );

                  //       })}
                  //     </select>
                  //   </div>
                  // </div>
                  <div className="col-md-4" style={{ marginBottom: 15 }}>
                    <Select
                      // options={populateshopOptions()}
                      // loadOptions={debounce(shopOptions,1000)}
                      options={
                        populateShops()

                      }
                      // options.unshift({key: "Choose a shop", value:""})
                      defaultValue={(selectedShop && selectedShop != null) ? { value: selectedShop?.value, label: selectedShop?.label } : null}
                      name="shop_id"
                      placeholder="Search Shop"

                      onChange={(e) => {

                        console.log("SELECTED SHOP", e);
                        setSelectedShop(e);
                        debouncedSave2(inputValue, e.value);


                        // dispatch(listProducts(1, inputValue, e.value));
                        // formik.setFieldValue("shop_id", e.value);
                      }}
                    />
                    {/* {console.log(shops)} */}
                  </div>


                ) : (
                    ""
                  )}
                {user.user.typeofuser === "S" ? (
                  <div className="col-md-3" style={{ marginBottom: 15 }}>
                    <div className="float-right">
                      <button
                        className="btn  btn-secondary"
                        onClick={() => {
                          history.push("/ecom/addnewproduct");
                          dispatch(listProductDetails(0));
                        }}
                      >
                        Add New Product
                      </button>
                    </div>

                  </div>
                ) : (
                    <div className="col-md-7" style={{ marginBottom: 15 }}>
                      <div className="float-right">
                        <button
                          className="btn  btn-secondary"
                          onClick={() => {
                            history.push("/ecom/addnewproduct");
                            dispatch(listProductDetails(0));
                          }}
                        >
                          Add New Product
                      </button>
                      </div>

                    </div>
                  )}

                {/*<div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={all.checked}
                onChange={(d) => {
                  all.checked === true ? (d = false) : (d = true);
                  setAll({ checked: d });
                }}
              />
              <label className="form-check-label">ALL</label>
            </div>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={deleted.checked}
                onChange={(d) => {
                  deleted.checked === true ? (d = false) : (d = true);
                  setDeleted({ checked: d });
                }}
              />
              <label className="form-check-label">DELETED</label>
            </div>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={active.checked}
                onChange={(d) => {
                  active.checked === true ? (d = false) : (d = true);
                  setActive({ checked: d });
                }}
              />
              <label className="form-check-label">ACTIVE</label>
              </div>*/}


              </div>

              <div className="row">
                {products &&
                  products.map((product) => (
                    <Products
                      key={product.key}
                      product={product}
                      history={history}
                    />
                  ))}
                {/* {console.log(products)} */}
                {products.length === 0 ? (
                  <div>
                    <h3>No Products Found</h3>
                  </div>
                ) : ("")
                }
              </div>
              <Pagination
                className={`mt-4 mb-2 pagination-gutter
        pagination-danger
      `}
              >
                {items}
              </Pagination>
            </Fragment>
          )}
    </>
  );
};

export default ProductGrid;
