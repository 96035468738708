import Product from "../models/productmodel";
import Variation from "../models/variationmodel";
import Shop from "../models/shopmodel";
import { BASE_URL } from "../constants/Globals";
import axios, { AxiosRequestConfig } from "axios";


class ShopsApi {

    static async getAllShops(): Promise<Shop[]> {

        // @ts-ignore
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        const config =
        {
            headers: {
                Authorization: `Bearer ${userInfo.success.token}`,
                Accept: 'application/json'

  
            },

        };

        let shopList: Shop[] = [];
        try {
            const response = await axios.get(
                `${BASE_URL}api/v2/admin/shopsreturn`,
                config
            );



            for (let i = 0; i< response.data.length; i++) {
                let shop: Shop = Shop.fromJson(response.data[i]);

                shopList.push(shop);
            }
        } catch (e) {
            console.log("Error when getting all shopsList: ", e);
            throw e.toString();
        }

        return shopList;
    }






}


export default ShopsApi;